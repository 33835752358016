import React, { useEffect } from "react";
import logo from "../../assets/img/logo.png";

function Menu() {
  useEffect(() => {
    const mainNav = document.getElementById("mainNav");
    const navbarShrink = () => {
      if (mainNav) {
        mainNav.style.backgroundColor = "var(--bs-primary-dark)";
        mainNav.style.borderBottom = "3px solid var(--bs-secondary)";
      }
    };

    const handleNavbarItemClick = () => {
      const btnToggler = document.querySelector(".navbar-toggler");
      const navbar = document.getElementById("navbarResponsive");
      if (navbar.classList.contains("show")) {
        btnToggler.click();
      }
    };


    navbarShrink();

    const responsiveNavItems = document.querySelectorAll(
      "#navbarResponsive .nav-link"
    );

    responsiveNavItems.forEach((responsiveNavItem) => {
      responsiveNavItem.addEventListener("click", handleNavbarItemClick);
    });

    const hash = window.location.hash;

    if (hash) {
      const href = hash.slice(1);
      const targetElement = document.querySelector(`[href="#${href}"]`);
      if (targetElement) {
        targetElement.click();
      }
    }

    return () => {
      document.removeEventListener("scroll", navbarShrink);
      responsiveNavItems.forEach((responsiveNavItem) => {
        if (hash) {
          responsiveNavItem.classList.toggle("active");
          responsiveNavItem.removeEventListener("click", handleNavbarItemClick);
        }
      });
    };
  });

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top"
      id="mainNav"
    >
      <div className="container px-4 px-lg-5">
        <img className="logo navbar-brand" src={logo} alt="Inicio"></img>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/#eventos">
                <span className="nav-text text-white-75">Eventos</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#galeria">
                <span className="nav-text text-white-75">Galeria</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/cerrarSesion">
                <span className="nav-text text-white-75">Cerrar Sesion</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
