import React from "react";

const today = new Date();
const year = today.getFullYear();

export default function Footer() {
  return (
    <div className="container">
      <div className="row col-md-12 px-4 px-lg-5">
        <div className="col-md-6 text-start">
          <p>
            Todos los derechos reservados a{" "}
            <span className="fw-bolder">&copy;Iglesia Bautista Esperanza</span>{" "}
            {year}.
            <br />
            Desarrollado por{" "}
            <a
              href="https://jiempy.cl"
              className="fw-bold text-decoration-none"
              rel="noopener noreferrer"
              target="_blank"
            >
              Jiempy SpA
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
