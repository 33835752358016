import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import config from "../../config.json";

export default function EventosDetalle() {
  const [dataEventos, setDataEventos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idEvento, setIdEvento] = useState(null);
  const params = useParams();

  const openModal = (id) => {
    setIdEvento(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const btn_allevent = document.getElementById("btn_allevent");

    let api = `${config.apiBaseUrl}/api/eventos/getall`;

    if (params.id) {
      api = `${config.apiBaseUrl}/api/eventos/getSingle/${params.id}`;
    } else {
      if (btn_allevent) {
        btn_allevent.remove();
      }
    }

    axios
      .get(api)
      .then((res) => {
        setDataEventos(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    const mainNav = document.getElementById("mainNav");
    const navbarShrink = () => {
      if (mainNav) {
        mainNav.style.backgroundColor = "var(--bs-primary-dark)";
        mainNav.style.borderBottom = "3px solid var(--bs-secondary)";
      }
    };

    navbarShrink();
  }, [params.id]);

  const listaEventos = dataEventos.map((evento) => {
    return (
      <ShowEventos key={evento.id} evento={evento} openModal={openModal} />
    );
  });

  return (
    <section className="text-center site-section">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="row justify-content-center mt-lg-4 pt-lg-4">
            <div className="col-md-12">
              <h2 className="mb-4">Eventos</h2>
            </div>
            <div className="d-flex justify-content-start mb-4">
              <button
                className="btn btn-info m-0 me-1 p-2 pt-1 pb-1"
                onClick={() => openModal(0)}
              >
                <i className="fa fa-plus"></i>
                <span> Nuevo</span>
              </button>
              <a className="btn btn-secondary m-0 me-1 p-2 pt-1 pb-1" href="/">
                <i className="fa fa-home"></i>
                <span> volver</span>
              </a>
            </div>
            <div className="card">
              <div className="row col-md-12 card-body">
                <div className="col-md-1">#</div>
                <div className="col-md-3">Fecha</div>
                <div className="col-md-6">Titulo</div>
                <div className="col-md-2">Estado</div>
              </div>
            </div>
            {listaEventos}
            <div className="d-flex justify-content-center">
              <a className="btn btn-secondary m-4 p-2" href="/">
                <i className="fa fa-home"></i>
                <span> volver</span>
              </a>
            </div>

            <ModalEvento
              isOpen={modalIsOpen}
              closeModal={closeModal}
              id={idEvento}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function ShowEventos({ evento, openModal }) {
  const moment = require("moment");
  require("moment/locale/es");
  moment.locale("es");

  const fecha = moment(evento.fecha_evento);
  const fechaFormateada = fecha.format("D/MM/YYYY HH:mm");

  let estado = "Publicado";
  if (evento.publicado !== 1) {
    estado = "Eliminado";
  }

  if (evento.create_by === "admin") {
    evento.create_by = "IBE";
  }

  return (
    <div className="card  text-align-left" key={evento.id}>
      <div className="row col-md-12 card-body">
        <div className="col-md-1">
          <button
            className="btn btn-success m-2 p-3 pt-1 pb-1"
            onClick={() => openModal(evento.id)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button className="btn btn-danger m-2 p-3 pt-1 pb-1">
            <i className="fa fa-trash"></i>
          </button>
        </div>
        <div className="col-md-3">{fechaFormateada}</div>
        <div className="col-md-6">{evento.titulo}</div>
        <div className="col-md-2">{estado}</div>
      </div>
    </div>
  );
}

function ModalEvento({ isOpen, closeModal, id }) {
  const [dataEvento, setDataEvento] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [fecha, setFecha] = useState(new Date());
  const [inputCheck, setInputCheck] = useState([false]);
  const handleDrop = (acceptedFiles) =>
    setFileNames(acceptedFiles.map((file) => file.name));

  const moment = require("moment");
  require("moment/locale/es");
  moment.locale("es");

  useEffect(() => {
    const fetchEvento = async (id) => {
      try {
        if (!dataEvento || dataEvento.id !== id) {
          const response = await axios.get(
            `${config.apiBaseUrl}/api/eventos/getSingle/${id}`
          );
          setDataEvento(response.data[0]);
          setFecha(response.data[0].fecha_evento);
          setInputCheck(response.data[0].publicado === 1 ? true : false);
        }
      } catch (error) {
        console.error("Error al obtener datos del evento", error);
      }
    };

    if (isOpen && id) {
      fetchEvento(id);
    }
  }, [isOpen, id, dataEvento, inputCheck]);

  const newFecha = moment(fecha);
  const fechaFormateada = newFecha.format("YYYY-MM-DD");
  const horaFormateada = newFecha.format("HH:mm");

  const guardarModal = () => {
    closeModal();
  };

  const customStyles = {
    overlay: {
      backgroundColor: "#000000bf",
    },
    content: {
      width: "80%",
      right: "10%",
      left: "10%",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      appElement={document.getElementById("root")}
    >
      <div className="row d-flex justify-content-center mt-8">
        <div className="col-md-8 card">
          <div className="card-header">
            <h2>Agregar/Actualizar</h2>
          </div>
          <div className="card-body">
            {dataEvento ? (
              <>
                <div className="row col-md-12">
                  <div className="col-md-12 mt-5">
                    <div className="form-group">
                      <label>Titulo</label>
                      <input
                        type="text"
                        className="form-control"
                        name="titulo"
                        id="titulo"
                        value={dataEvento.titulo}
                        onChange={(e) =>
                          setDataEvento({
                            ...dataEvento,
                            titulo: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mt-5">
                    <div className="form-group">
                      <label>Fecha</label>
                      <input
                        type="date"
                        className="form-control"
                        name="fecha"
                        id="fecha"
                        value={fechaFormateada}
                        onChange={(e) => fechaFormateada(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mt-5">
                    <div className="form-group">
                      <label>Hora</label>
                      <input
                        type="time"
                        className="form-control"
                        name="hora"
                        id="hora"
                        value={horaFormateada}
                        onChange={(e) => horaFormateada(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3 mt-5">
                    <div className="form-group">
                      <label>Prioridad</label>
                      <select
                        className="form-control f"
                        name="prioridad"
                        id="prioridad"
                        defaultValue={dataEvento.prioridad}
                        onChange={(e) =>
                          setDataEvento({
                            ...dataEvento,
                            prioridad: e.target.value,
                          })
                        }
                      >
                        <option value={0}>Baja</option>
                        <option value={1}>Normal</option>
                        <option value={2}>Alta</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 mt-5">
                    <div className="form-group">
                      <label>Publicar</label>
                      <input
                        type="checkbox"
                        className="form-control form-check-input p-3"
                        name="estado"
                        id="estado"
                        checked={inputCheck}
                        onChange={(e) => inputCheck(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mt-5">
                    <label>Descripción</label>
                    <textarea
                      type="text"
                      className="form-control p-3"
                      name="descripcion"
                      id="descripcion"
                      value={dataEvento.descripcion}
                      onChange={(e) =>
                        setDataEvento({
                          ...dataEvento,
                          descripcion: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div className="col-md-12 mt-5">
                    <Dropzone
                      onDrop={handleDrop}
                      accept={{
                        "image/jpeg": [".jpeg", ".jpg"],
                        "image/png": [".png"],
                      }}
                      minSize={1024}
                      maxSize={3072000}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      }) => {
                        const additionalClass = isDragAccept
                          ? "accept"
                          : isDragReject
                          ? "reject"
                          : "";

                        return (
                          <div
                            {...getRootProps({
                              className: `dropzone dropzone d-flex justify-content-center flex-column  align-items-center p-3 ${additionalClass}`,
                            })}
                          >
                            <input {...getInputProps()} />

                            <i className="fa fa-cloud-upload icon fa-4x" />
                            <br />
                            <span>
                              Arrastre y suelte imágenes o haga clic para
                              seleccionar archivos
                            </span>
                          </div>
                        );
                      }}
                    </Dropzone>

                    <div>
                      <ul className="navbar-nav ms-auto">
                        {fileNames.map((fileName) => (
                          <li key={fileName} className="nav-item">
                            <span>
                              <i className="fa fa-image"></i> {fileName}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <p>Cargando...</p>
            )}
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <div className="row col-md-3">
                <button className="btn btn-danger p-2" onClick={closeModal}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
              </div>
              <div className="row col-md-3">
                <button className="btn btn-success p-2" onClick={guardarModal}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
