import React from 'react'

export default function Home() {
  return (
    <section className="site-section" id="Home">
      <div className="container px-4 px-lg-5">
        <div className="row col-md-12 gx-4 gx-lg-5">
          <div className="row col-md-12 mb-3 mb-md-0">
            <div className="col-md-6 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="/intranet/eventos"
                rel="noopener noreferrer"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-calendar text-primary mb-2"></i>
                    <h4 className="text-uppercase m-0">Eventos</h4>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>Editar/Modificar/Eliminar</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="/intranet/galeria"
                rel="noopener noreferrer"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-image text-primary mb-2"></i>
                    <h4 className="text-uppercase m-0">Galeria</h4>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>Editar/Modificar/Eliminar</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
