import React from "react";
import DeclaracionTooltip from "./DeclaracionTooltip";

export default function Declaracion() {
  return (
    <section className="text-justify site-section" id="declaracion">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="row justify-content-center mt-lg-4 pt-lg-4">
            <div className="col-ms-12 text-center">
              <h2 className="mb-4 fw-bolder">Nuestra declaración de Fe</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <p className="mx-4 mb-5 text-center">
                Pero tú habla lo que está de acuerdo con la sana doctrina.{" "}
                <b>(Tito 2:1)</b>
              </p>
              <p className="mx-4 mb-5">
                Creemos los siguientes artículos de fe:
              </p>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div
                    className="accordion accordion-flush"
                    id="accordion_declaracion"
                  >
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4"
                        id="flush-heading_1"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_1"
                          aria-expanded="false"
                          aria-controls="flush-collapse_1"
                        >
                          1. LAS SAGRADAS ESCRITURAS
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_1"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_1"
                        data-bs-parent="#accordion_1"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos que las Santas Escrituras del
                            Antiguo y Nuevo Testamentos son la revelación
                            escrita de Dios. Nosotros creemos en la inspiración
                            verbal y plenaria de las Escrituras, y la
                            preservación de Dios de Sus puras palabras a cada
                            generación.
                            <b>
                              {" ("}
                              <DeclaracionTooltip
                                id={"2 Timoteo 3:16; Salmo 12:6-7"}
                                content="<b>2 Timoteo 3:16 </b>
                                         <br/> <b>16</b> Toda la Escritura es inspirada por Dios, y útil para enseñar, para redargüir, para corregir, para instruir en justicia. 
                                         <br/> <br/>
                                         <b>Salmo 12:6-7 </b> 
                                         <br/><b>6</b> Las palabras de Jehová son palabras puras, como plata refinada en horno de tierra, purificada siete veces. 
                                         </br><b>7</b> Tú, oh Jehová, los guardarás;los protegerás para siempre de esta generación."
                              />
                              {")"}
                            </b>
                            <br />
                            La versión de la Biblia <b>Reina Valera 1960</b> es
                            la versión castellana que nosotros aceptamos y
                            usamos. La Biblia es nuestra única autoridad para la
                            fe y práctica
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_2">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_2"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_2"
                          aria-expanded="false"
                          aria-controls="flush-collapse_2"
                        >
                          2. EL DIOS VERDADERO
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_2"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_2"
                        data-bs-parent="#accordion_2"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos en un Dios trino, existiendo
                            eternamente en tres personas - Padre, Hijo y
                            Espíritu Santo, siendo co-eterno, co-idéntico en
                            naturaleza, co-igual en el poder y gloria, y
                            teniendo los mismos atributos y perfecciones.
                            <b>
                              {" ("}
                              <DeclaracionTooltip
                                id={"Deuteronomio 6:4; 2 Corintios 13:14"}
                                content="<b>Deuteronomio 6:4</b>
                                         <br/><b>4</b> Oye, Israel: Jehová nuestro Dios, Jehová uno es. 
                                         <br/><br/><b>2 Corintios 13:14</b>
                                         <br/><b>14</b> La gracia del Señor Jesucristo, el amor de Dios, y la comunión del Espíritu Santo sean con todos vosotros. Amén."
                              />
                              {")"}
                            </b>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_3">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_3"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_3"
                          aria-expanded="false"
                          aria-controls="flush-collapse_3"
                        >
                          3. LA PERSONA Y OBRA DE NUESTRO SEÑOR JESUCRISTO
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_3"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_3"
                        data-bs-parent="#accordion_3"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos que el Señor Jesucristo, el Hijo
                            eterno de Dios, se volvió hombre, sin dejar de ser
                            Dios, y fue concebido por el Espíritu Santo y nacido
                            de la virgen María, para que Él pudiera revelar a
                            Dios y redimir a los hombres pecadores.
                            <b>
                              {" ("}
                              <DeclaracionTooltip
                                id={
                                  "Juan 1:1-2, 14; Lucas 1:35; Isaías 9:6-7, 17; Filipenses 2:5-8; Gálatas 4:4-5"
                                }
                                content="<b>Juan 1:1-2, 14</b>
                                         <br/><b>1</b> En el principio era el Verbo, y el Verbo era con Dios, y el Verbo era Dios. 
                                         <br/><b>2</b> Este era en el principio con Dios.
                                         <br/><b>14</b> Y aquel Verbo fue hecho carne, y habitó entre nosotros (y vimos su gloria, 
                                                        gloria como del unigénito del Padre), lleno de gracia y de verdad.
                                         <br/><br/>
                                         <b>Lucas 1:35</b>
                                         <br/><b>35</b> Respondiendo el ángel, le dijo: El Espíritu Santo vendrá sobre ti, 
                                                        y el poder del Altísimo te cubrirá con su sombra; por lo cual también 
                                                        el Santo Ser que nacerá, será llamado Hijo de Dios. 
                                         <br/><br/>
                                         <b>Isaías 9:6-7, 17</b>
                                         <br/><b>6</b> Porque un niño nos es nacido, hijo nos es dado, y el principado sobre su hombro; 
                                                       y se llamará su nombre Admirable, Consejero, Dios Fuerte, Padre Eterno, Príncipe de Paz. 
                                         <br/><b>7</b> Lo dilatado de su imperio y la paz no tendrán límite, sobre el trono de David y sobre su reino, 
                                                       disponiéndolo y confirmándolo en juicio y en justicia desde ahora y para siempre. 
                                                       El celo de Jehová de los ejércitos hará esto.
                                        <br/><b>17</b> Por tanto, el Señor no tomará contentamiento en sus jóvenes, 
                                                       ni de sus huérfanos y viudas tendrá misericordia; porque todos son falsos y malignos, 
                                                       y toda boca habla despropósitos. Ni con todo esto ha cesado su furor, 
                                                       sino que todavía su mano está extendida.
                                         <br/><br/>
                                         <b>Filipenses 2:5-8</b>
                                         <br/><b>5</b> Haya, pues, en vosotros este sentir que hubo también en Cristo Jesús, 
                                         <br/><b>6</b> el cual, siendo en forma de Dios, no estimó el ser igual a Dios como cosa a que aferrarse, 
                                         <br/><b>7</b> sino que se despojó a sí mismo, tomando forma de siervo, hecho semejante a los hombres; 
                                         <br/><b>8</b> y estando en la condición de hombre, se humilló a sí mismo, haciéndose obediente hasta la muerte, y muerte de cruz. 
                                         <br/><br/>
                                         <b>Gálatas 4:4-5</b>
                                         <br/><b>4</b> Pero cuando vino el cumplimiento del tiempo, Dios envió a su Hijo, nacido de mujer y nacido bajo la ley, 
                                         <br/><b>5</b> para que redimiese a los que estaban bajo la ley, a fin de que recibiésemos la adopción de hijos. "
                              />
                              {")"}
                            </b>
                          </p>
                          <p>
                            Nosotros creemos que el Señor Jesucristo logró
                            nuestra redención a través de Su obra, finalizada en
                            la cruz en sacrificio vicario; y que nuestra
                            justificación es un hecho seguro por Su resurrección
                            literal y física de la muerte{" "}
                            <b>
                              {" ("}
                              <DeclaracionTooltip
                                id={
                                  "Romanos 3:24-26; 1 Pedro 1:3-5; 1 Pedro 2:24; Efesios 1:7"
                                }
                                content="<b>Romanos 3:24-26</b>
                                         <br/><b>24</b> siendo justificados gratuitamente por su gracia, mediante la redención que es en Cristo Jesús, 
                                         <br/><b>25</b> a quien Dios puso como propiciación por medio de la fe en su sangre, para manifestar su justicia, 
                                                      a causa de haber pasado por alto, en su paciencia, los pecados pasados, 
                                         <br/><b>26</b> con la mira de manifestar en este tiempo su justicia, a fin de que él sea el justo, y el que justifica al que es de la fe de Jesús.
                                         <br/><br/>
                                         <b>1 Pedro 1:3-5</b>
                                         <br/><b>3</b> Bendito el Dios y Padre de nuestro Señor Jesucristo, 
                                                       que según su grande misericordia nos hizo renacer para una esperanza viva, 
                                                       por la resurrección de Jesucristo de los muertos, 
                                         <br/><b>4</b> para una herencia incorruptible, incontaminada e inmarcesible, reservada en los cielos para vosotros, 
                                         <br/><b>5</b> que sois guardados por el poder de Dios mediante la fe, para alcanzar la salvación que está preparada 
                                                       para ser manifestada en el tiempo postrero.                                          
                                         <br/><br/>
                                         <b>1 Pedro 2:24</b>
                                         <br/><b>24</b> quien llevó él mismo nuestros pecados en su cuerpo sobre el madero, para que nosotros, 
                                                      estando muertos a los pecados, vivamos a la justicia; y por cuya herida fuisteis sanados.
                                         <br/><br/>
                                         <b>Efesios 1:7</b>
                                         <br/><b>7</b> en quien tenemos redención por su sangre, el perdón de pecados según las riquezas de su gracia,"
                              />
                              {")"}
                            </b>
                            .
                          </p>
                          <p>
                            Nosotros creemos que el Señor Jesucristo ascendió al
                            cielo, y es ahora exaltado a la mano derecha de Dios
                            donde, como nuestro Sumo Sacerdote, Él cumple el
                            ministerio de Intercesor y Abogado.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Hechos 1:9-10; Hebreos 7:25, 9:24; Romanos 8:34; 1 Juan 2:1-2)"
                                }
                                content="<b>Hechos 1:9-10</b>
                                         <br/><b>9</b> Y habiendo dicho estas cosas, viéndolo ellos, fue alzado, y le recibió una nube que le ocultó de sus ojos. 
                                         <br/><b>10</b> Y estando ellos con los ojos puestos en el cielo, entre tanto que él se iba, he aquí se pusieron junto a ellos dos varones con vestiduras blancas, 
                                         <br/><br/>
							                    			 <b>Hebreos  7:25</b>
                                         <br/><b>25</b> por lo cual puede también salvar perpetuamente a los que por él se acercan a Dios, viviendo siempre para interceder por ellos.
                                         <br/><br/>
							                    			 <b>Hebreos  9:24</b>
                                         <br/><b>24</b> Porque no entró Cristo en el santuario hecho de mano, figura del verdadero, sino en el cielo mismo para presentarse ahora por nosotros ante Dios;
                                         <br/><br/>
							                    			 <b>Romanos 8:34</b>
                                         <br/><b>34</b> ¿Quién es el que condenará? Cristo es el que murió; más aun, el que también resucitó, el que además está a la diestra de Dios, el que también intercede por nosotros. 
                                         <br/><br/>
							                    			 <b>1 Juan 2:1-2</b>
                                         <br/><b>1</b> Hijitos míos, estas cosas os escribo para que no pequéis; y si alguno hubiere pecado, abogado tenemos para con el Padre, a Jesucristo el justo. 
                                         <br/><b>2</b> Y él es la propiciación por nuestros pecados; y no solamente por los nuestros, sino también por los de todo el mundo."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_4">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_4"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_4"
                          aria-expanded="false"
                          aria-controls="flush-collapse_4"
                        >
                          4. LA PERSONA Y OBRA DEL ESPÍRITU SANTO
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_4"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_4"
                        data-bs-parent="#accordion_4"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos que el Espíritu Santo es la Persona
                            que convence al mundo de pecado, de justicia y de
                            juicio; y que Él es el Agente Sobrenatural en la
                            regeneración, mientras bautiza a todos los creyentes
                            en el cuerpo de Cristo, sellándolos hacia el día de
                            la redención.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Juan 16:7-11; 1 Corintios 12:12-14; 2 Corintios 3:6; Romanos 8:9; Efesios 1:13-14)"
                                }
                                content="<b>Juan 16:7-11</b>
                                        <br/><b>7</b> Pero yo os digo la verdad: Os conviene que yo me vaya; porque si no me fuera, el Consolador no vendría a vosotros; mas si me fuere, os lo enviaré.
                                        <br/><b>8</b> Y cuando él venga, convencerá al mundo de pecado, de justicia y de juicio.
                                        <br/><b>9</b> De pecado, por cuanto no creen en mí;
                                        <br/><b>10</b> de justicia, por cuanto voy al Padre, y no me veréis más;
                                        <br/><b>11</b> y de juicio, por cuanto el príncipe de este mundo ha sido ya juzgado.
                                        <br/><br/>
                                        <b>1 Corintios 12:12-14</b>
                                        <br/><b>12</b> Porque así como el cuerpo es uno, y tiene muchos miembros, pero todos los miembros del cuerpo, siendo muchos, son un solo cuerpo, así también Cristo.
                                        <br/><b>13</b> Porque por un solo Espíritu fuimos todos bautizados en un cuerpo, sean judíos o griegos, sean esclavos o libres; y a todos se nos dio a beber de un mismo Espíritu.
                                        <br/><b>14</b> Además, el cuerpo no es un solo miembro, sino muchos. 
                                        <br/><br/>
                                        <b>2 Corintios 3:6</b>
                                        <br/><b>6</b> el cual asimismo nos hizo ministros competentes de un nuevo pacto, no de la letra, sino del espíritu; porque la letra mata, mas el espíritu vivifica.
                                        <br/><br/>
                                        <b>Romanos 8:9</b>
                                        <br/><b>9</b> Mas vosotros no vivís según la carne, sino según el Espíritu, si es que el Espíritu de Dios mora en vosotros. Y si alguno no tiene el Espíritu de Cristo, no es de él.
                                        <br/><br/>
                                        <b>Efesios 1:13-14</b>
                                        <br/><b>13</b> En él también vosotros, habiendo oído la palabra de verdad, el evangelio de vuestra salvación, y habiendo creído en él, fuisteis sellados con el Espíritu Santo de la promesa,
                                        <br/><b>14</b> que es las arras de nuestra herencia hasta la redención de la posesión adquirida, para alabanza de su gloria."
                              />
                            </b>
                          </p>
                          <p>
                            Nosotros creemos que los dones o señales del
                            Espíritu Santo, como hablar en lenguas y sanidades,
                            eran temporales. Nosotros creemos que el hablar en
                            lenguas nunca era la señal común o necesaria del
                            bautismo o llenura del Espíritu Santo, y que nuestro
                            cuerpo espera la consumación de nuestra salvación en
                            la resurrección, aunque Dios frecuentemente escoge
                            responder la oración de los creyentes por la sanidad
                            física.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(1 Corintios 1:22, 13:8, 14:21-22; 2 Corintios 12:12; Hebreos 2:3-4; Marcos 16:17-20)"
                                }
                                content="<b>1 Corintios 1:22</b>
                                        <br/><b>22</b> Porque los judíos piden señales, y los griegos buscan sabiduría;
                                        <br/><br/>
                                        <b>1 Corintios 13:8</b>
                                        <br/><b>8</b> El amor nunca deja de ser; pero las profecías se acabarán, y cesarán las lenguas, y la ciencia acabará.
                                        <br/><br/>
                                        <b>1 Corintios 14:21-22</b>
                                        <br/><b>21</b> En la ley está escrito: En otras lenguas y con otros labios hablaré a este pueblo; y ni aun así me oirán, dice el Señor.
                                        <br/><b>22</b> Así que, las lenguas son por señal, no a los creyentes, sino a los incrédulos; pero la profecía, no a los incrédulos, sino a los creyentes. 
                                        <br/><br/>
                                        <b>2 Corintios 12:12</b>
                                        <br/><b>12</b> Con todo, las señales de apóstol han sido hechas entre vosotros en toda paciencia, por señales, prodigios y milagros.
                                        <br/><br/>
                                        <b>Hebreos 2:3-4</b>
                                        <br/><b>3</b> ¿cómo escaparemos nosotros, si descuidamos una salvación tan grande? La cual, habiendo sido anunciada primeramente por el Señor, nos fue confirmada por los que oyeron, 
                                        <br/><b>4</b> testificando Dios juntamente con ellos, con señales y prodigios y diversos milagros y repartimientos del Espíritu Santo según su voluntad.
                                        <br/><br/>
                                        <b>Marcos 16:17-20</b>
                                        <br/><b>17</b> Y estas señales seguirán a los que creen: En mi nombre echarán fuera demonios; hablarán nuevas lenguas;
                                        <br/><b>18</b> tomarán en las manos serpientes, y si bebieren cosa mortífera, no les hará daño; sobre los enfermos pondrán sus manos, y sanarán.
                                        <br/><b>19</b> Y el Señor, después que les habló, fue recibido arriba en el cielo, y se sentó a la diestra de Dios.
                                        <br/><b>20</b> Y ellos, saliendo, predicaron en todas partes, ayudándoles el Señor y confirmando la palabra con las señales que la seguían. Amén."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_5">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_5"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_5"
                          aria-expanded="false"
                          aria-controls="flush-collapse_5"
                        >
                          5. EL HOMBRE
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_5"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_5"
                        data-bs-parent="#accordion_5"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos que el hombre se creó a imagen y
                            semejanza de Dios, pero que con el pecado de Adán la
                            raza se corrompió, heredando una naturaleza
                            pecadora, y se alejó de Dios; y ese hombre es
                            totalmente depravado e incapaz de remediar su
                            condición perdida.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Génesis 1:26-27; Romanos 3:22-23; Efesios 2:1-3,12)"
                                }
                                content="<b>Génesis 1:26-27</b>
                                        <br/><b>26</b> ntonces dijo Dios: Hagamos al hombre a nuestra imagen, conforme a nuestra semejanza; y señoree en los peces del mar, en las aves de los cielos, en las bestias, en toda la tierra, y en todo animal que se arrastra sobre la tierra.
                                        <br/><b>27</b> Y creó Dios al hombre a su imagen, a imagen de Dios lo creó; varón y hembra los creó.
                                        <br/><br/>
                                        <b>Romanos 3:22-23</b>
                                        <br/><b>22</b> la justicia de Dios por medio de la fe en Jesucristo, para todos los que creen en él. Porque no hay diferencia,
                                        <br/><b>23</b> por cuanto todos pecaron, y están destituidos de la gloria de Dios,
                                        <br/><br/>
                                        <b>Efesios 2:1-3,12</b>
                                        <br/><b>1</b> Y él os dio vida a vosotros, cuando estabais muertos en vuestros delitos y pecados,
                                        <br/><b>2</b> en los cuales anduvisteis en otro tiempo, siguiendo la corriente de este mundo, conforme al príncipe de la potestad del aire, el espíritu que ahora opera en los hijos de desobediencia,
                                        <br/><b>3</b> entre los cuales también todos nosotros vivimos en otro tiempo en los deseos de nuestra carne, haciendo la voluntad de la carne y de los pensamientos, y éramos por naturaleza hijos de ira, lo mismo que los demás.
                                        <br/><b>12</b> En aquel tiempo estabais sin Cristo, alejados de la ciudadanía de Israel y ajenos a los pactos de la promesa, sin esperanza y sin Dios en el mundo."
                              />
                            </b>
                          </p>
                          <p>
                            Creemos que Dios creó al hombre a Su propia imagen y
                            semejanza, y en que Cristo murió por el hombre; por
                            consiguiente cada individuo posee la dignidad y es
                            digno de respeto en el amor cristiano.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={"(Salmo 8:4-9; Colosenses 3:9-11)"}
                                content="<b>Salmo 8:4-9</b>
                                        <br/><b>4</b> Digo: ¿Qué es el hombre, para que tengas de él memoria, Y el hijo del hombre, para que lo visites?
                                        <br/><b>5</b> Le has hecho poco menor que los ángeles, Y lo coronaste de gloria y de honra.
                                        <br/><b>6</b> Le hiciste señorear sobre las obras de tus manos; Todo lo pusiste debajo de sus pies:
                                        <br/><b>7</b> Ovejas y bueyes, todo ello, Y asimismo las bestias del campo,
                                        <br/><b>8</b> Las aves de los cielos y los peces del mar; Todo cuanto pasa por los senderos del mar.
                                        <br/><b>9</b> ¡Oh Jehová, Señor nuestro, Cuán grande es tu nombre en toda la tierra!
                                        <br/><br/>
                                        <b>Colosenses 3:9-11</b>
                                        <br/><b>9</b> No mintáis los unos a los otros, habiéndoos despojado del viejo hombre con sus hechos, 
                                        <br/><b>10</b> y revestido del nuevo, el cual conforme a la imagen del que lo creó se va renovando hasta el conocimiento pleno,
                                        <br/><b>11</b> donde no hay griego ni judío, circuncisión ni incircuncisión, bárbaro ni escita, siervo ni libre, sino que Cristo es el todo, y en todos."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_6">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_6"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_6"
                          aria-expanded="false"
                          aria-controls="flush-collapse_6"
                        >
                          6. LA FORMA DE SALVACIÓN
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_6"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_6"
                        data-bs-parent="#accordion_6"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos que el mensaje claro de salvación
                            es el "arrepentimiento hacia Dios y fe hacia nuestro
                            Señor Jesucristo"
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={"(Hechos 20:21)"}
                                content="<b>Hechos 20:21</b>
                                        <br/><b>21</b> testificando a judíos y a gentiles acerca del arrepentimiento para con Dios, y de la fe en nuestro Señor Jesucristo."
                              />
                            </b>
                          </p>
                          <p>
                            Nosotros creemos que los hombres están justificados
                            por la fe sola y sólo se consideran virtuosos ante
                            Dios a través del mérito de nuestro Señor y Salvador
                            Jesucristo.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Efesios 2:8-10; Juan 1:12; 1 Pedro 1:18-19)"
                                }
                                content="<b>Efesios 2:8-10</b>
                                        <br/><b>8</b> Porque por gracia sois salvos por medio de la fe; y esto no de vosotros, pues es don de Dios; 
                                        <br/><b>9</b> no por obras, para que nadie se gloríe. 
                                        <br/><b>10</b> Porque somos hechura suya, creados en Cristo Jesús para buenas obras, las cuales Dios preparó de antemano para que anduviésemos en ellas.
                                        <br/><br/>
                                        <b>Juan 1:12</b>
                                        <br/><b>12</b> Mas a todos los que le recibieron, a los que creen en su nombre, les dio potestad de ser hechos hijos de Dios; 
                                        <br/><br/>
                                        <b>1 Pedro 1:18-19</b>
                                        <br/><b>18</b> sabiendo que fuisteis rescatados de vuestra vana manera de vivir, la cual recibisteis de vuestros padres, no con cosas corruptibles, como oro o plata,
                                        <br/><b>19</b> sino con la sangre preciosa de Cristo, como de un cordero sin mancha y sin contaminación,"
                              />
                            </b>
                          </p>
                          <p>
                            Nosotros creemos que a todos los que Él rescató y
                            una vez salvó, son guardados por el poder de Dios y
                            están así para siempre seguros en Cristo. Nosotros
                            creemos que la vida eterna es la posesión presente
                            de cada creyente.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Juan 6:37-40; Juan 10:27-30; Romanos 8:1,38-39; 1 Corintios 1:4-8; 1 Pedro 1:3-5; Judas 1:24)"
                                }
                                content="<b>Juan 6:37-40</b>
                                        <br/><b>37</b> Todo lo que el Padre me da, vendrá a mí; y al que a mí viene, no le echo fuera.
                                        <br/><b>38</b> Porque he descendido del cielo, no para hacer mi voluntad, sino la voluntad del que me envió.
                                        <br/><b>39</b> Y esta es la voluntad del Padre, el que me envió: Que de todo lo que me diere, no pierda yo nada, sino que lo resucite en el día postrero.
                                        <br/><b>40</b> Y esta es la voluntad del que me ha enviado: Que todo aquel que ve al Hijo, y cree en él, tenga vida eterna; y yo le resucitaré en el día postrero.
                                        <br/><br/>
                                        <b>Juan 10:27-30</b>
                                        <br/><b>27</b> Mis ovejas oyen mi voz, y yo las conozco, y me siguen,
                                        <br/><b>28</b> y yo les doy vida eterna; y no perecerán jamás, ni nadie las arrebatará de mi mano.
                                        <br/><b>29</b> Mi Padre que me las dio, es mayor que todos, y nadie las puede arrebatar de la mano de mi Padre.
                                        <br/><b>30</b> Yo y el Padre uno somos.
                                        <br/><br/>
                                        <b>Romanos 8:1,38-39</b>
                                        <br/><b>1</b> y los que viven según la carne no pueden agradar a Dios.
                                        <br/><b>38</b> Por lo cual estoy seguro de que ni la muerte, ni la vida, ni ángeles, ni principados, ni potestades, ni lo presente, ni lo por venir,
                                        <br/><b>39</b> ni lo alto, ni lo profundo, ni ninguna otra cosa creada nos podrá separar del amor de Dios, que es en Cristo Jesús Señor nuestro.
                                        <br/><br/>
                                        <b>1 Corintios 1:4-8</b>
                                        <br/><b>4</b> Gracias doy a mi Dios siempre por vosotros, por la gracia de Dios que os fue dada en Cristo Jesús;
                                        <br/><b>5</b> porque en todas las cosas fuisteis enriquecidos en él, en toda palabra y en toda ciencia;
                                        <br/><b>6</b> así como el testimonio acerca de Cristo ha sido confirmado en vosotros,
                                        <br/><b>7</b> de tal manera que nada os falta en ningún don, esperando la manifestación de nuestro Señor Jesucristo;
                                        <br/><b>8</b> el cual también os confirmará hasta el fin, para que seáis irreprensibles en el día de nuestro Señor Jesucristo.
                                        <br/><br/>
                                        <b>1 Pedro 1:3-5</b>
                                        <br/><b>3</b> Bendito el Dios y Padre de nuestro Señor Jesucristo, que según su grande misericordia nos hizo renacer para una esperanza viva, por la resurrección de Jesucristo de los muertos,
                                        <br/><b>4</b> para una herencia incorruptible, incontaminada e inmarcesible, reservada en los cielos para vosotros,
                                        <br/><b>5</b> que sois guardados por el poder de Dios mediante la fe, para alcanzar la salvación que está preparada para ser manifestada en el tiempo postrero.
                                        <br/><br/>
                                        <b>Judas 1:24</b>
                                        <br/><b>24</b> Y a aquel que es poderoso para guardaros sin caída, y presentaros sin mancha delante de su gloria con gran alegría,"
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_7">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_7"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_7"
                          aria-expanded="false"
                          aria-controls="flush-collapse_7"
                        >
                          7. LA IGLESIA
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_7"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_7"
                        data-bs-parent="#accordion_7"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos que la iglesia es el cuerpo y la
                            novia desposada de Cristo, un organismo espiritual
                            compuesto de toda persona renacida, que empezó con
                            Cristo y los apóstoles, fue llena de poder en
                            Pentecostés y será llevada por Cristo en el Rapto.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Efesios 1:22-23; Efesios 5:25-27; 1 Corintios 12:12-14; 2 Corintios 11:2)"
                                }
                                content="<b>Efesios 1:22-23</b>
                                        <br/><b>22</b> y sometió todas las cosas bajo sus pies, y lo dio por cabeza sobre todas las cosas a la iglesia,
                                        <br/><b>23</b> la cual es su cuerpo, la plenitud de Aquel que todo lo llena en todo.
                                        <br/><br/>
                                        <b>Efesios 5:25-27</b>
                                        <br/><b>25</b> Maridos, amad a vuestras mujeres, así como Cristo amó a la iglesia, y se entregó a sí mismo por ella,
                                        <br/><b>26</b> para santificarla, habiéndola purificado en el lavamiento del agua por la palabra,
                                        <br/><b>27</b> a fin de presentársela a sí mismo, una iglesia gloriosa, que no tuviese mancha ni arruga ni cosa semejante, sino que fuese santa y sin mancha.
                                        <br/><br/>
                                        <b>1 Corintios 12:12-14</b>
                                        <br/><b>12</b> Porque así como el cuerpo es uno, y tiene muchos miembros, pero todos los miembros del cuerpo, siendo muchos, son un solo cuerpo, así también Cristo.
                                        <br/><b>13</b> Porque por un solo Espíritu fuimos todos bautizados en un cuerpo, sean judíos o griegos, sean esclavos o libres; y a todos se nos dio a beber de un mismo Espíritu.
                                        <br/><b>14</b> Además, el cuerpo no es un solo miembro, sino muchos.
                                        <br/><br/>
                                        <b>2 Corintios 11:2</b>
                                        <br/><b>2</b> Porque os celo con celo de Dios; pues os he desposado con un solo esposo, para presentaros como una virgen pura a Cristo."
                              />
                            </b>
                          </p>
                          <p>
                            Nosotros creemos en la enseñanza del establecimiento
                            de iglesias locales claramente definida en las
                            Escrituras del Nuevo Testamento.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Hechos 14:27; Hechos 20:17, 28-32; 1 Timoteo 3:1-13; Tito 1:5-9)"
                                }
                                content="<b>Hechos 14:27</b>
                                        <br/><b>27</b> Cuando lo oyeron los apóstoles Bernabé y Pablo, rasgaron sus ropas, y se lanzaron entre la multitud, dando voces
                                        <br/><br/>
                                        <b>Hechos 20:17, 28-32</b>
                                        <br/><b>17</b> Enviando, pues, desde Mileto a Éfeso, hizo llamar a los ancianos de la iglesia.
                                        <br/><b>28</b> Por tanto, mirad por vosotros, y por todo el rebaño en que el Espíritu Santo os ha puesto por obispos, para apacentar la iglesia del Señor, la cual él ganó por su propia sangre.
                                        <br/><b>29</b> Porque yo sé que después de mi partida entrarán en medio de vosotros lobos rapaces, que no perdonarán al rebaño.
                                        <br/><b>30</b> Y de vosotros mismos se levantarán hombres que hablen cosas perversas para arrastrar tras sí a los discípulos.
                                        <br/><b>31</b> Por tanto, velad, acordándoos que por tres años, de noche y de día, no he cesado de amonestar con lágrimas a cada uno.
                                        <br/><b>32</b> Y ahora, hermanos, os encomiendo a Dios, y a la palabra de su gracia, que tiene poder para sobreedificaros y daros herencia con todos los santificados.
                                        <br/><br/>
                                        <b>1 Timoteo 3:1-13</b>
                                        <br/><b>1</b> Palabra fiel: Si alguno anhela obispado, buena obra desea.
                                        <br/><b>2</b> Pero es necesario que el obispo sea irreprensible, marido de una sola mujer, sobrio, prudente, decoroso, hospedador, apto para enseñar;
                                        <br/><b>3</b> no dado al vino, no pendenciero, no codicioso de ganancias deshonestas, sino amable, apacible, no avaro;
                                        <br/><b>4</b> que gobierne bien su casa, que tenga a sus hijos en sujeción con toda honestidad
                                        <br/><b>5</b> (pues el que no sabe gobernar su propia casa, ¿cómo cuidará de la iglesia de Dios?);
                                        <br/><b>6</b> no un neófito, no sea que envaneciéndose caiga en la condenación del diablo.
                                        <br/><b>7</b> También es necesario que tenga buen testimonio de los de afuera, para que no caiga en descrédito y en lazo del diablo.
                                        <br/><b>8</b> Los diáconos asimismo deben ser honestos, sin doblez, no dados a mucho vino, no codiciosos de ganancias deshonestas;
                                        <br/><b>9</b> que guarden el misterio de la fe con limpia conciencia.
                                        <br/><b>10</b> Y estos también sean sometidos a prueba primero, y entonces ejerzan el diaconado, si son irreprensibles.
                                        <br/><b>11</b> Las mujeres asimismo sean honestas, no calumniadoras, sino sobrias, fieles en todo.
                                        <br/><b>12</b> Los diáconos sean maridos de una sola mujer, y que gobiernen bien sus hijos y sus casas.
                                        <br/><b>13</b> Porque los que ejerzan bien el diaconado, ganan para sí un grado honroso, y mucha confianza en la fe que es en Cristo Jesús.
                                        <br/><br/>
                                        <b>Tito 1:5-9</b>
                                        <br/><b>5</b> Por esta causa te dejé en Creta, para que corrigieses lo deficiente, y establecieses ancianos en cada ciudad, así como yo te mandé;
                                        <br/><b>6</b> el que fuere irreprensible, marido de una sola mujer, y tenga hijos creyentes que no estén acusados de disolución ni de rebeldía.
                                        <br/><b>7</b> Porque es necesario que el obispo sea irreprensible, como administrador de Dios; no soberbio, no iracundo, no dado al vino, no pendenciero, no codicioso de ganancias deshonestas,
                                        <br/><b>8</b> sino hospedador, amante de lo bueno, sobrio, justo, santo, dueño de sí mismo,
                                        <br/><b>9</b> retenedor de la palabra fiel tal como ha sido enseñada, para que también pueda exhortar con sana enseñanza y convencer a los que contradicen."
                              />
                            </b>
                            Nosotros creemos en la autonomía de la iglesia local
                            libre, sin cualquier autoridad externa o mando.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Hechos 13:1-4; Hechos 15:19-31; Hechos 20:28; Romanos 16:1-4; 1 Corintios 3:9, 16; 1 Corintios 5:3-8, 13; 1 Pedro 5:1-4)"
                                }
                                content="<b>Hechos 13:1-4</b>
                                        <br/><b>1</b> Había entonces en la iglesia que estaba en Antioquía, profetas y maestros: Bernabé, Simón el que se llamaba Niger, Lucio de Cirene, Manaén el que se había criado junto con Herodes el tetrarca, y Saulo.
                                        <br/><b>2</b> Ministrando estos al Señor, y ayunando, dijo el Espíritu Santo: Apartadme a Bernabé y a Saulo para la obra a que los he llamado.
                                        <br/><b>3</b> Entonces, habiendo ayunado y orado, les impusieron las manos y los despidieron.
                                        <br/><b>4</b> Ellos, entonces, enviados por el Espíritu Santo, descendieron a Seleucia, y de allí navegaron a Chipre.
                                        <br/><br/>
                                        <b>Hechos 15:19-31</b>
                                        <br/><b>19</b> Por lo cual yo juzgo que no se inquiete a los gentiles que se convierten a Dios,
                                        <br/><b>20</b> sino que se les escriba que se aparten de las contaminaciones de los ídolos, de fornicación, de ahogado y de sangre.
                                        <br/><b>21</b> Porque Moisés desde tiempos antiguos tiene en cada ciudad quien lo predique en las sinagogas, donde es leído cada día de reposo.
                                        <br/><b>22</b> Entonces pareció bien a los apóstoles y a los ancianos, con toda la iglesia, elegir de entre ellos varones y enviarlos a Antioquía con Pablo y Bernabé: a Judas que tenía por sobrenombre Barsabás, y a Silas, varones principales entre los hermanos;
                                        <br/><b>23</b> y escribir por conducto de ellos: Los apóstoles y los ancianos y los hermanos, a los hermanos de entre los gentiles que están en Antioquía, en Siria y en Cilicia, salud.
                                        <br/><b>24</b> Por cuanto hemos oído que algunos que han salido de nosotros, a los cuales no dimos orden, os han inquietado con palabras, perturbando vuestras almas, mandando circuncidaros y guardar la ley,
                                        <br/><b>25</b> nos ha parecido bien, habiendo llegado a un acuerdo, elegir varones y enviarlos a vosotros con nuestros amados Bernabé y Pablo,
                                        <br/><b>26</b> hombres que han expuesto su vida por el nombre de nuestro Señor Jesucristo.
                                        <br/><b>27</b> Así que enviamos a Judas y a Silas, los cuales también de palabra os harán saber lo mismo.
                                        <br/><b>28</b> Porque ha parecido bien al Espíritu Santo, y a nosotros, no imponeros ninguna carga más que estas cosas necesarias:
                                        <br/><b>29</b> que os abstengáis de lo sacrificado a ídolos, de sangre, de ahogado y de fornicación; de las cuales cosas si os guardareis, bien haréis. Pasadlo bien.
                                        <br/><b>30</b> Así, pues, los que fueron enviados descendieron a Antioquía, y reuniendo a la congregación, entregaron la carta;
                                        <br/><b>31</b> habiendo leído la cual, se regocijaron por la consolación.
                                        <br/><br/>
                                        <b>Hechos 20:28</b>
                                        <br/><b>28</b> Por tanto, mirad por vosotros, y por todo el rebaño en que el Espíritu Santo os ha puesto por obispos, para apacentar la iglesia del Señor, la cual él ganó por su propia sangre.
                                        <br/><br/>
                                        <b>Romanos 16:1-4</b>
                                        <br/><b>1</b> Os recomiendo además nuestra hermana Febe, la cual es diaconisa de la iglesia en Cencrea;
                                        <br/><b>2</b> que la recibáis en el Señor, como es digno de los santos, y que la ayudéis en cualquier cosa en que necesite de vosotros; porque ella ha ayudado a muchos, y a mí mismo.
                                        <br/><b>3</b> Saludad a Priscila y a Aquila, mis colaboradores en Cristo Jesús,
                                        <br/><b>4</b> que expusieron su vida por mí; a los cuales no solo yo doy gracias, sino también todas las iglesias de los gentiles.
                                        <br/><br/>
                                        <b>1 Corintios 3:9, 16</b>
                                        <br/><b>9</b> Porque nosotros somos colaboradores de Dios, y vosotros sois labranza de Dios, edificio de Dios.
                                        <br/><b>16</b> ¿No sabéis que sois templo de Dios, y que el Espíritu de Dios mora en vosotros?
                                        <br/><br/>
                                        <b>1 Corintios 5:3-8, 13</b>
                                        <br/><b>3</b> Ciertamente yo, como ausente en cuerpo, pero presente en espíritu, ya como presente he juzgado al que tal cosa ha hecho.
                                        <br/><b>4</b> En el nombre de nuestro Señor Jesucristo, reunidos vosotros y mi espíritu, con el poder de nuestro Señor Jesucristo,
                                        <br/><b>5</b> el tal sea entregado a Satanás para destrucción de la carne, a fin de que el espíritu sea salvo en el día del Señor Jesús.
                                        <br/><b>6</b> No es buena vuestra jactancia. ¿No sabéis que un poco de levadura leuda toda la masa?
                                        <br/><b>7</b> Limpiaos, pues, de la vieja levadura, para que seáis nueva masa, sin levadura como sois; porque nuestra pascua, que es Cristo, ya fue sacrificada por nosotros.
                                        <br/><b>8</b> Así que celebremos la fiesta, no con la vieja levadura, ni con la levadura de malicia y de maldad, sino con panes sin levadura, de sinceridad y de verdad.
                                        <br/><b>13</b> Porque a los que están fuera, Dios juzgará. Quitad, pues, a ese perverso de entre vosotros.
                                        <br/><br/>
                                        <b>1 Pedro 5:1-4</b>
                                        <br/><b>1</b> Ruego a los ancianos que están entre vosotros, yo anciano también con ellos, y testigo de los padecimientos de Cristo, que soy también participante de la gloria que será revelada:
                                        <br/><b>2</b> Apacentad la grey de Dios que está entre vosotros, cuidando de ella, no por fuerza, sino voluntariamente; no por ganancia deshonesta, sino con ánimo pronto;
                                        <br/><b>3</b> no como teniendo señorío sobre los que están a vuestro cuidado, sino siendo ejemplos de la grey.
                                        <br/><b>4</b> Y cuando aparezca el Príncipe de los pastores, vosotros recibiréis la corona incorruptible de gloria."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_8">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_8"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_8"
                          aria-expanded="false"
                          aria-controls="flush-collapse_8"
                        >
                          8. LAS ORDENANZAS DE LA IGLESIA
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_8"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_8"
                        data-bs-parent="#accordion_8"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            {" "}
                            Nosotros reconocemos las ordenanzas del bautismo por
                            inmersión en el agua y la Cena del Señor como un
                            medio de testimonio para la iglesia en esta edad{" "}
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Mateo 28:19-20; Hechos 2:41-42; 1 Corintios 11:23-26)"
                                }
                                content="<b>Mateo 28:19-20</b>
                                        <br/><b>19</b> Por tanto, id, y haced discípulos a todas las naciones, bautizándolos en el nombre del Padre, y del Hijo, y del Espíritu Santo;
                                        <br/><b>20</b> enseñándoles que guarden todas las cosas que os he mandado; y he aquí yo estoy con vosotros todos los días, hasta el fin del mundo. Amén.
                                        <br/><br/>
                                        <b>Hechos 2:41-42</b>
                                        <br/><b>41</b> Así que, los que recibieron su palabra fueron bautizados; y se añadieron aquel día como tres mil personas.
                                        <br/><b>42</b> Y perseveraban en la doctrina de los apóstoles, en la comunión unos con otros, en el partimiento del pan y en las oraciones.
                                        <br/><br/>
                                        <b>1 Corintios 11:23-26</b>
                                        <br/><b>23</b> Porque yo recibí del Señor lo que también os he enseñado: Que el Señor Jesús, la noche que fue entregado, tomó pan;
                                        <br/><b>24</b> y habiendo dado gracias, lo partió, y dijo: Tomad, comed; esto es mi cuerpo que por vosotros es partido; haced esto en memoria de mí.
                                        <br/><b>25</b> Asimismo tomó también la copa, después de haber cenado, diciendo: Esta copa es el nuevo pacto en mi sangre; haced esto todas las veces que la bebiereis, en memoria de mí.
                                        <br/><b>26</b> Así, pues, todas las veces que comiereis este pan, y bebiereis esta copa, la muerte del Señor anunciáis hasta que él venga."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_9">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_9"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_9"
                          aria-expanded="false"
                          aria-controls="flush-collapse_9"
                        >
                          9. LAS FINANZAS
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_9"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_9"
                        data-bs-parent="#accordion_9"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Creemos que es el privilegio y el deber de cada
                            creyente dar de sus recursos para el sostén
                            económico de su iglesia. Este debe incluir el sostén
                            del pastor, la manutención del templo, ayuda a los
                            pobres y otros gastos relacionados a la proclamación
                            del Evangelio.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(1 Corintios 9:14; 1 Corintios 16:1-2; Gálatas 6:6; 2 Corintios 9:7; Malaquías 3:8-11)"
                                }
                                content="<b>1 Corintios 9:14</b>
                                        <br/><b>14</b> Así también ordenó el Señor a los que anuncian el evangelio, que vivan del evangelio.
                                        <br/><br/>
                                        <b>1 Corintios 16:1-2</b>
                                        <br/><b>1</b> En cuanto a la ofrenda para los santos, haced vosotros también de la manera que ordené en las iglesias de Galacia.
                                        <br/><b>2</b> Cada primer día de la semana cada uno de vosotros ponga aparte algo, según haya prosperado, guardándolo, para que cuando yo llegue no se recojan entonces ofrendas.
                                        <br/><br/>
                                        <b>Gálatas 6:6</b>
                                        <br/><b>6</b> El que es enseñado en la palabra, haga partícipe de toda cosa buena al que lo instruye.
                                        <br/><br/>
                                        <b>2 Corintios 9:7</b>
                                        <br/><b>7</b> Cada uno dé como propuso en su corazón: no con tristeza, ni por necesidad, porque Dios ama al dador alegre.
                                        <br/><br/>
                                        <b>Malaquías 3:8-11</b>
                                        <br/><b>8</b> ¿Robará el hombre a Dios? Pues vosotros me habéis robado. Y dijisteis: ¿En qué te hemos robado? En vuestros diezmos y ofrendas.
                                        <br/><b>9</b> Malditos sois con maldición, porque vosotros, la nación toda, me habéis robado.
                                        <br/><b>10</b> Traed todos los diezmos al alfolí y haya alimento en mi casa; y probadme ahora en esto, dice Jehová de los ejércitos, si no os abriré las ventanas de los cielos, y derramaré sobre vosotros bendición hasta que sobreabunde.
                                        <br/><b>11</b> Reprenderé también por vosotros al devorador, y no os destruirá el fruto de la tierra, ni vuestra vid en el campo será estéril, dice Jehová de los ejércitos."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_10">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_10"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_10"
                          aria-expanded="false"
                          aria-controls="flush-collapse_10"
                        >
                          10. LA GRAN COMISIÓN
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_10"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_10"
                        data-bs-parent="#accordion_10"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Creemos que las Escrituras enseñan que la Gran
                            Comisión de la iglesia es dar sin error a todas las
                            naciones la Palabra de Dios, por medio de nuestras
                            enseñanzas y ejemplo.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={"(Mateo 28:18-20; Marcos 16:14-18)"}
                                content="<b>Mateo 28:18-20</b>
                                        <br/><b>18</b> Y Jesús se acercó y les habló diciendo: Toda potestad me es dada en el cielo y en la tierra.
                                        <br/><b>19</b> Por tanto, id, y haced discípulos a todas las naciones, bautizándolos en el nombre del Padre, y del Hijo, y del Espíritu Santo;
                                        <br/><b>20</b> enseñándoles que guarden todas las cosas que os he mandado; y he aquí yo estoy con vosotros todos los días, hasta el fin del mundo. Amén.
                                        <br/><br/>
                                        <b>Marcos 16:14-18</b>
                                        <br/><b>14</b> Finalmente se apareció a los once mismos, estando ellos sentados a la mesa, y les reprochó su incredulidad y dureza de corazón, porque no habían creído a los que le habían visto resucitado.
                                        <br/><b>15</b> Y les dijo: Id por todo el mundo y predicad el evangelio a toda criatura.
                                        <br/><b>16</b> El que creyere y fuere bautizado, será salvo; mas el que no creyere, será condenado.
                                        <br/><b>17</b> Y estas señales seguirán a los que creen: En mi nombre echarán fuera demonios; hablarán nuevas lenguas;
                                        <br/><b>18</b> tomarán en las manos serpientes, y si bebieren cosa mortífera, no les hará daño; sobre los enfermos pondrán sus manos, y sanarán."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_11">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_11"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_11"
                          aria-expanded="false"
                          aria-controls="flush-collapse_11"
                        >
                          11. SISTEMA DE GOBIERNO
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_11"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_11"
                        data-bs-parent="#accordion_11"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Creemos que el sistema de gobierno de una iglesia es
                            congregacional, es decir, que todos los miembros son
                            iguales y que la iglesia mantiene su independencia y
                            soberanía con autonomía en todos sus actos.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Mateo 23:5-12; Hechos 11:26; Hechos 13:1-3; Filipenses 4:10-19)"
                                }
                                content="<b>Mateo 23:5-12</b>
                                        <br/><b>5</b> Antes, hacen todas sus obras para ser vistos por los hombres. Pues ensanchan sus filacterias, y extienden los flecos de sus mantos;
                                        <br/><b>6</b> y aman los primeros asientos en las cenas, y las primeras sillas en las sinagogas,
                                        <br/><b>7</b> y las salutaciones en las plazas, y que los hombres los llamen: Rabí, Rabí.
                                        <br/><b>8</b> Pero vosotros no queráis que os llamen Rabí; porque uno es vuestro Maestro, el Cristo, y todos vosotros sois hermanos.
                                        <br/><b>9</b> Y no llaméis padre vuestro a nadie en la tierra; porque uno es vuestro Padre, el que está en los cielos.
                                        <br/><b>10</b> Ni seáis llamados maestros; porque uno es vuestro Maestro, el Cristo.
                                        <br/><b>11</b> El que es el mayor de vosotros, sea vuestro siervo.
                                        <br/><b>12</b> Porque el que se enaltece será humillado, y el que se humilla será enaltecido.
                                        <br/><br/>
                                        <b>Hechos 11:26</b>
                                        <br/><b>26</b> Y se congregaron allí todo un año con la iglesia, y enseñaron a mucha gente; y a los discípulos se les llamó cristianos por primera vez en Antioquía.
                                        <br/><br/>
                                        <b>Hechos 13:1-3</b>
                                        <br/><b>1</b> Había entonces en la iglesia que estaba en Antioquía, profetas y maestros: Bernabé, Simón el que se llamaba Niger, Lucio de Cirene, Manaén el que se había criado junto con Herodes el tetrarca, y Saulo.
                                        <br/><b>2</b> Ministrando estos al Señor, y ayunando, dijo el Espíritu Santo: Apartadme a Bernabé y a Saulo para la obra a que los he llamado.
                                        <br/><b>3</b> Entonces, habiendo ayunado y orado, les impusieron las manos y los despidieron.
                                        <br/><br/>
                                        <b>Filipenses 4:10-19</b>
                                        <br/><b>10</b> En gran manera me gocé en el Señor de que ya al fin habéis revivido vuestro cuidado de mí; de lo cual también estabais solícitos, pero os faltaba la oportunidad.
                                        <br/><b>11</b> No lo digo porque tenga escasez, pues he aprendido a contentarme, cualquiera que sea mi situación.
                                        <br/><b>12</b> Sé vivir humildemente, y sé tener abundancia; en todo y por todo estoy enseñado, así para estar saciado como para tener hambre, así para tener abundancia como para padecer necesidad.
                                        <br/><b>13</b> Todo lo puedo en Cristo que me fortalece.
                                        <br/><b>14</b> Sin embargo, bien hicisteis en participar conmigo en mi tribulación.
                                        <br/><b>15</b> Y sabéis también vosotros, oh filipenses, que al principio de la predicación del evangelio, cuando partí de Macedonia, ninguna iglesia participó conmigo en razón de dar y recibir, sino vosotros solos;
                                        <br/><b>16</b> pues aun a Tesalónica me enviasteis una y otra vez para mis necesidades.
                                        <br/><b>17</b> No es que busque dádivas, sino que busco fruto que abunde en vuestra cuenta.
                                        <br/><b>18</b> Pero todo lo he recibido, y tengo abundancia; estoy lleno, habiendo recibido de Epafrodito lo que enviasteis; olor fragante, sacrificio acepto, agradable a Dios.
                                        <br/><b>19</b> Mi Dios, pues, suplirá todo lo que os falta conforme a sus riquezas en gloria en Cristo Jesús."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_12">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_12"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_12"
                          aria-expanded="false"
                          aria-controls="flush-collapse_12"
                        >
                          12. SUS OFICIALES
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_12"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_12"
                        data-bs-parent="#accordion_12"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Creemos que la iglesia tiene dos oficiales: pastor u
                            obispo y diáconos. Estos y todos sus miembros tienen
                            la sagrada y sublime misión de predicar el evangelio
                            hasta el fin del mundo.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(1 Timoteo 3:1-16; Hechos 6:3-6; Hechos 14:23; Hechos 20:17,28; Tito 1:5-9;  1 Pedro 2:5; 1 Pedro 5:1-3)"
                                }
                                content="<b>1 Timoteo 3:1-16</b>
                                        <br/><b>1</b> Palabra fiel: Si alguno anhela obispado, buena obra desea.
                                        <br/><b>2</b> Pero es necesario que el obispo sea irreprensible, marido de una sola mujer, sobrio, prudente, decoroso, hospedador, apto para enseñar;
                                        <br/><b>3</b> no dado al vino, no pendenciero, no codicioso de ganancias deshonestas, sino amable, apacible, no avaro;
                                        <br/><b>4</b> que gobierne bien su casa, que tenga a sus hijos en sujeción con toda honestidad
                                        <br/><b>5</b> (pues el que no sabe gobernar su propia casa, ¿cómo cuidará de la iglesia de Dios?);
                                        <br/><b>6</b> no un neófito, no sea que envaneciéndose caiga en la condenación del diablo.
                                        <br/><b>7</b> ambién es necesario que tenga buen testimonio de los de afuera, para que no caiga en descrédito y en lazo del diablo.
                                        <br/><b>8</b> Los diáconos asimismo deben ser honestos, sin doblez, no dados a mucho vino, no codiciosos de ganancias deshonestas;
                                        <br/><b>9</b> que guarden el misterio de la fe con limpia conciencia.
                                        <br/><b>10</b> Y estos también sean sometidos a prueba primero, y entonces ejerzan el diaconado, si son irreprensibles. 
                                        <br/><b>11</b> Las mujeres asimismo sean honestas, no calumniadoras, sino sobrias, fieles en todo.
                                        <br/><b>12</b> Los diáconos sean maridos de una sola mujer, y que gobiernen bien sus hijos y sus casas.
                                        <br/><b>13</b> Porque los que ejerzan bien el diaconado, ganan para sí un grado honroso, y mucha confianza en la fe que es en Cristo Jesús.
                                        <br/><b>14</b> Esto te escribo, aunque tengo la esperanza de ir pronto a verte,
                                        <br/><b>15</b> para que si tardo, sepas cómo debes conducirte en la casa de Dios, que es la iglesia del Dios viviente, columna y baluarte de la verdad.
                                        <br/><b>16</b> E indiscutiblemente, grande es el misterio de la piedad: Dios fue manifestado en carne, Justificado en el Espíritu, Visto de los ángeles, Predicado a los gentiles, Creído en el mundo, Recibido arriba en gloria.
                                        <br/><br/>
                                        <b>Hechos 6:3-6</b>
                                        <br/><b>3</b> Buscad, pues, hermanos, de entre vosotros a siete varones de buen testimonio, llenos del Espíritu Santo y de sabiduría, a quienes encarguemos de este trabajo.
                                        <br/><b>4</b> Y nosotros persistiremos en la oración y en el ministerio de la palabra.
                                        <br/><b>5</b> Agradó la propuesta a toda la multitud; y eligieron a Esteban, varón lleno de fe y del Espíritu Santo, a Felipe, a Prócoro, a Nicanor, a Timón, a Parmenas, y a Nicolás prosélito de Antioquía;
                                        <br/><b>6</b> a los cuales presentaron ante los apóstoles, quienes, orando, les impusieron las manos.
                                        <br/><br/>
                                        <b>Hechos 14:23</b>
                                        <br/><b>23</b> Y constituyeron ancianos en cada iglesia, y habiendo orado con ayunos, los encomendaron al Señor en quien habían creído.
                                        <br/><br/>
                                        <b>Hechos 20:17, 28</b>
                                        <br/><b>17</b> Enviando, pues, desde Mileto a Éfeso, hizo llamar a los ancianos de la iglesia.
                                        <br/><b>28</b> Por tanto, mirad por vosotros, y por todo el rebaño en que el Espíritu Santo os ha puesto por obispos, para apacentar la iglesia del Señor, la cual él ganó por su propia sangre.
                                        <br/><br/>
                                        <b>Tito 1:5-9</b>
                                        <br/><b>5</b> Por esta causa te dejé en Creta, para que corrigieses lo deficiente, y establecieses ancianos en cada ciudad, así como yo te mandé;
                                        <br/><b>6</b> el que fuere irreprensible, marido de una sola mujer, y tenga hijos creyentes que no estén acusados de disolución ni de rebeldía.
                                        <br/><b>7</b> Porque es necesario que el obispo sea irreprensible, como administrador de Dios; no soberbio, no iracundo, no dado al vino, no pendenciero, no codicioso de ganancias deshonestas,
                                        <br/><b>8</b> sino hospedador, amante de lo bueno, sobrio, justo, santo, dueño de sí mismo,
                                        <br/><b>9</b> retenedor de la palabra fiel tal como ha sido enseñada, para que también pueda exhortar con sana enseñanza y convencer a los que contradicen.
                                        <br/><br/> 
                                        <b>1 Pedro 2:5</b>
                                        <br/><b>5</b> vosotros también, como piedras vivas, sed edificados como casa espiritual y sacerdocio santo, para ofrecer sacrificios espirituales aceptables a Dios por medio de Jesucristo.
                                        <br/><br/>
                                        <b>1 Pedro 5:1-3</b>
                                        <br/><b>1</b> Ruego a los ancianos que están entre vosotros, yo anciano también con ellos, y testigo de los padecimientos de Cristo, que soy también participante de la gloria que será revelada:
                                        <br/><b>2</b> Apacentad la grey de Dios que está entre vosotros, cuidando de ella, no por fuerza, sino voluntariamente; no por ganancia deshonesta, sino con ánimo pronto;
                                        <br/><b>3</b> no como teniendo señorío sobre los que están a vuestro cuidado, sino siendo ejemplos de la grey."
                              />
                            </b>
                          </p>
                          <p>
                            Reconocemos al Señor Jesucristo como la única cabeza
                            de la iglesia
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Mateo 28:20; Colosenses 1:18-19; Colosenses 2:10,19; Efesios 1:22)"
                                }
                                content="<b>Mateo 28:20</b>
                                        <br/><b>20</b> enseñándoles que guarden todas las cosas que os he mandado; y he aquí yo estoy con vosotros todos los días, hasta el fin del mundo. Amén.
                                        <br/><br/>
                                        <b>Colosenses 1:18-19</b>
                                        <br/><b>18</b> y él es la cabeza del cuerpo que es la iglesia, él que es el principio, el primogénito de entre los muertos, para que en todo tenga la preeminencia;
                                        <br/><b>19</b> por cuanto agradó al Padre que en él habitase toda plenitud,
                                        <br/><br/>
                                        <b>Colosenses 2:10,19</b>
                                        <br/><b>10</b> y vosotros estáis completos en él, que es la cabeza de todo principado y potestad.
                                        <br/><b>19</b> y no asiéndose de la Cabeza, en virtud de quien todo el cuerpo, nutriéndose y uniéndose por las coyunturas y ligamentos, crece con el crecimiento que da Dios.
                                        <br/><br/>
                                        <b>Efesios 1:22</b>
                                        <br/><b>22</b> y sometió todas las cosas bajo sus pies, y lo dio por cabeza sobre todas las cosas a la iglesia,"
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_13">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_13"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_13"
                          aria-expanded="false"
                          aria-controls="flush-collapse_13"
                        >
                          13. LA SEPARACIÓN BÍBLICA
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_13"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_13"
                        data-bs-parent="#accordion_13"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos que todo al que Él salvó debe vivir
                            de tal manera como para no traer reproche a su
                            Salvador y Señor; y esa separación de toda la
                            apostasía religiosa, placeres mundanos y de
                            pecadores, son ordenadas por Dios
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(2 Timoteo 3:1-5; Romanos 12:1-2; Romanos 14:13; 1 Juan 2:15-17; 2 Juan 9-11; 2 Corintios 6:14; 2 Corintios 7:1)"
                                }
                                content="<b>2 Timoteo 3:1-5</b>
                                        <br/><b>1</b> También debes saber esto: que en los postreros días vendrán tiempos peligrosos.
                                        <br/><b>2</b> Porque habrá hombres amadores de sí mismos, avaros, vanagloriosos, soberbios, blasfemos, desobedientes a los padres, ingratos, impíos,
                                        <br/><b>3</b> sin afecto natural, implacables, calumniadores, intemperantes, crueles, aborrecedores de lo bueno,
                                        <br/><b>4</b> traidores, impetuosos, infatuados, amadores de los deleites más que de Dios,
                                        <br/><b>5</b> que tendrán apariencia de piedad, pero negarán la eficacia de ella; a estos evita.
                                        <br/><br/>
                                        <b>Romanos 12:1-2</b>
                                        <br/><b>1</b> Así que, hermanos, os ruego por las misericordias de Dios, que presentéis vuestros cuerpos en sacrificio vivo, santo, agradable a Dios, que es vuestro culto racional.
                                        <br/><b>2</b> No os conforméis a este siglo, sino transformaos por medio de la renovación de vuestro entendimiento, para que comprobéis cuál sea la buena voluntad de Dios, agradable y perfecta.
                                        <br/><br/>
                                        <b>Romanos 14:13</b>
                                        <br/><b>13</b> Así que, ya no nos juzguemos más los unos a los otros, sino más bien decidid no poner tropiezo u ocasión de caer al hermano.
                                        <br/><br/>
                                        <b>1 Juan 2:15-17</b>
                                        <br/><b>15</b> No améis al mundo, ni las cosas que están en el mundo. Si alguno ama al mundo, el amor del Padre no está en él.
                                        <br/><b>16</b> Porque todo lo que hay en el mundo, los deseos de la carne, los deseos de los ojos, y la vanagloria de la vida, no proviene del Padre, sino del mundo.
                                        <br/><b>17</b> Y el mundo pasa, y sus deseos; pero el que hace la voluntad de Dios permanece para siempre.
                                        <br/><br/>
                                        <b>2 Juan 9:11</b>
                                        <br/><b>11</b> Porque el que le dice: ¡Bienvenido! participa en sus malas obras.
                                        <br/><br/>
                                        <b>2 Corintios 6:14</b>
                                        <br/><b>14</b> No os unáis en yugo desigual con los incrédulos; porque ¿qué compañerismo tiene la justicia con la injusticia? ¿Y qué comunión la luz con las tinieblas?
                                        <br/><br/>
                                        <b>2 Corintios 7:1</b>
                                        <br/><b>1</b> Así que, amados, puesto que tenemos tales promesas, limpiémonos de toda contaminación de carne y de espíritu, perfeccionando la santidad en el temor de Dios."
                              />
                            </b>
                            .
                          </p>
                          <p>
                            Creemos que debe haber completa separación entre el
                            Estado y la Iglesia, siendo cada cosa buena e
                            importante en su lugar.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={"(Mateo 22:21; Efesios 1:22)"}
                                content="<b>Mateo 22:21</b>
                                        <br/><b>21</b> Le dijeron: De César. Y les dijo: Dad, pues, a César lo que es de César, y a Dios lo que es de Dios.
                                        <br/><br/>
                                        <b>Efesios 1:22</b>
                                        <br/><b>22</b> y sometió todas las cosas bajo sus pies, y lo dio por cabeza sobre todas las cosas a la iglesia,"
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_14">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_14"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_14"
                          aria-expanded="false"
                          aria-controls="flush-collapse_14"
                        >
                          14. EL RETORNO DE CRISTO
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_14"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_14"
                        data-bs-parent="#accordion_14"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos en la esperanza bendita del retorno
                            de nuestro Señor, que es literal, personal, visible,
                            inminente, pretribulacional y premilenial. Él vendrá
                            por Su iglesia en el rapto antes de los siete años
                            de Tribulación, y al final de la Tribulación, Cristo
                            volverá con Sus santos a establecer Su reino de mil
                            años en la tierra.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(1 Tesalonicenses 1:10; 1 Tesalonicenses 4:13-18; Tito 2:13; Apocalipsis 3:10; Apocalipsis 19:11-16; Apocalipsis 20:1-6; Zacarías 14:4-11; Salmo 89:3-4)"
                                }
                                content="<b>1 Tesalonicenses 1:10</b>
                                        <br/><b>10</b> y esperar de los cielos a su Hijo, al cual resucitó de los muertos, a Jesús, quien nos libra de la ira venidera.
                                        <br/><br/>
                                        <b>1 Tesalonicenses 4:13-18</b>
                                        <br/><b>13</b> Tampoco queremos, hermanos, que ignoréis acerca de los que duermen, para que no os entristezcáis como los otros que no tienen esperanza.
                                        <br/><b>14</b> Porque si creemos que Jesús murió y resucitó, así también traerá Dios con Jesús a los que durmieron en él.
                                        <br/><b>15</b> Por lo cual os decimos esto en palabra del Señor: que nosotros que vivimos, que habremos quedado hasta la venida del Señor, no precederemos a los que durmieron.
                                        <br/><b>16</b> Porque el Señor mismo con voz de mando, con voz de arcángel, y con trompeta de Dios, descenderá del cielo; y los muertos en Cristo resucitarán primero.
                                        <br/><b>17</b> Luego nosotros los que vivimos, los que hayamos quedado, seremos arrebatados juntamente con ellos en las nubes para recibir al Señor en el aire, y así estaremos siempre con el Señor.
                                        <br/><b>18</b> Por tanto, alentaos los unos a los otros con estas palabras.
                                        <br/><br/>
                                        <b>Tito 2:13</b>
                                        <br/><b>13</b> aguardando la esperanza bienaventurada y la manifestación gloriosa de nuestro gran Dios y Salvador Jesucristo,
                                        <br/><br/>
                                        <b>Apocalipsis 3:10</b>
                                        <br/><b>10</b> Por cuanto has guardado la palabra de mi paciencia, yo también te guardaré de la hora de la prueba que ha de venir sobre el mundo entero, para probar a los que moran sobre la tierra.
                                        <br/><br/>
                                        <b>Apocalipsis 19:11-16</b>
                                        <br/><b>11</b> Entonces vi el cielo abierto; y he aquí un caballo blanco, y el que lo montaba se llamaba Fiel y Verdadero, y con justicia juzga y pelea.
                                        <br/><b>12</b> Sus ojos eran como llama de fuego, y había en su cabeza muchas diademas; y tenía un nombre escrito que ninguno conocía sino él mismo.
                                        <br/><b>13</b> Estaba vestido de una ropa teñida en sangre; y su nombre es: EL VERBO DE DIOS.
                                        <br/><b>14</b> Y los ejércitos celestiales, vestidos de lino finísimo, blanco y limpio, le seguían en caballos blancos.
                                        <br/><b>15</b> De su boca sale una espada aguda, para herir con ella a las naciones, y él las regirá con vara de hierro; y él pisa el lagar del vino del furor y de la ira del Dios Todopoderoso.
                                        <br/><b>16</b> Y en su vestidura y en su muslo tiene escrito este nombre: REY DE REYES Y SEÑOR DE SEÑORES.
                                        <br/><br/>
                                        <b>Apocalipsis 20:1-6</b>
                                        <br/><b>1</b> Vi a un ángel que descendía del cielo, con la llave del abismo, y una gran cadena en la mano.
                                        <br/><b>2</b> Y prendió al dragón, la serpiente antigua, que es el diablo y Satanás, y lo ató por mil años;
                                        <br/><b>3</b> y lo arrojó al abismo, y lo encerró, y puso su sello sobre él, para que no engañase más a las naciones, hasta que fuesen cumplidos mil años; y después de esto debe ser desatado por un poco de tiempo.
                                        <br/><b>4</b> Y vi tronos, y se sentaron sobre ellos los que recibieron facultad de juzgar; y vi las almas de los decapitados por causa del testimonio de Jesús y por la palabra de Dios, los que no habían adorado a la bestia ni a su imagen, y que no recibieron la marca en sus frentes ni en sus manos; y vivieron y reinaron con Cristo mil años.
                                        <br/><b>5</b> Pero los otros muertos no volvieron a vivir hasta que se cumplieron mil años. Esta es la primera resurrección.
                                        <br/><b>6</b> Bienaventurado y santo el que tiene parte en la primera resurrección; la segunda muerte no tiene potestad sobre estos, sino que serán sacerdotes de Dios y de Cristo, y reinarán con él mil años.
                                        <br/><br/>
                                        <b>Zacarías 14:4-11</b>
                                        <br/><b>4</b> Y se afirmarán sus pies en aquel día sobre el monte de los Olivos, que está en frente de Jerusalén al oriente; y el monte de los Olivos se partirá por en medio, hacia el oriente y hacia el occidente, haciendo un valle muy grande; y la mitad del monte se apartará hacia el norte, y la otra mitad hacia el sur.
                                        <br/><b>5</b> Y huiréis al valle de los montes, porque el valle de los montes llegará hasta Azal; huiréis de la manera que huisteis por causa del terremoto en los días de Uzías rey de Judá; y vendrá Jehová mi Dios, y con él todos los santos.
                                        <br/><b>6</b> Y acontecerá que en ese día no habrá luz clara, ni oscura.
                                        <br/><b>7</b> Será un día, el cual es conocido de Jehová, que no será ni día ni noche; pero sucederá que al caer la tarde habrá luz.
                                        <br/><b>8</b> Acontecerá también en aquel día, que saldrán de Jerusalén aguas vivas, la mitad de ellas hacia el mar oriental, y la otra mitad hacia el mar occidental, en verano y en invierno.
                                        <br/><b>9</b> Y Jehová será rey sobre toda la tierra. En aquel día Jehová será uno, y uno su nombre.
                                        <br/><b>10</b> Toda la tierra se volverá como llanura desde Geba hasta Rimón al sur de Jerusalén; y esta será enaltecida, y habitada en su lugar desde la puerta de Benjamín hasta el lugar de la puerta primera, hasta la puerta del Ángulo, y desde la torre de Hananeel hasta los lagares del rey.
                                        <br/><b>11</b> Y morarán en ella, y no habrá nunca más maldición, sino que Jerusalén será habitada confiadamente.
                                        <br/><br/>
                                        <b>Salmo 89:3-4</b>
                                        <br/><b>3</b> Hice pacto con mi escogido; Juré a David mi siervo, diciendo:
                                        <br/><b>4</b> Para siempre confirmaré tu descendencia, Y edificaré tu trono por todas las generaciones."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="card py-4">
                <div className="card-body text-center">
                  <div className="accordion accordion-flush" id="accordion_15">
                    <div className="accordion-item">
                      <h4
                        className="text-uppercase m-0 hy-100 fs-4 accordion-header"
                        id="flush-heading_15"
                      >
                        <button
                          className="accordion-button accordion-none fs-4 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapse_15"
                          aria-expanded="false"
                          aria-controls="flush-collapse_15"
                        >
                          15. NUESTRO ESTADO ETERNO
                        </button>
                      </h4>
                      <hr className="my-4 mx-auto" />
                      <div
                        id="flush-collapse_15"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-heading_15"
                        data-bs-parent="#accordion_15"
                      >
                        <div className="accordion-body small text-black-50 text-justify p-0">
                          <p>
                            Nosotros creemos en la resurrección corporal de
                            todos los hombres: los salvos, a la vida eterna, y
                            los inconversos al castigo eterno. <br />
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Mateo 25:46; Juan 5:28-29; Juan 11:25-26; Apocalipsis 20:5-6, Apocalipsis 12-13)"
                                }
                                content="<b>Mateo 25:46</b>
                                        <br/><b>46</b> E irán estos al castigo eterno, y los justos a la vida eterna.
                                        <br/><br/>
                                        <b>Juan 5:28-29</b>
                                        <br/><b>28</b> No os maravilléis de esto; porque vendrá hora cuando todos los que están en los sepulcros oirán su voz;
                                        <br/><b>29</b> y los que hicieron lo bueno, saldrán a resurrección de vida; mas los que hicieron lo malo, a resurrección de condenación.
                                        <br/><br/>
                                        <b>Juan 11:25-26</b>
                                        <br/><b>25</b> Le dijo Jesús: Yo soy la resurrección y la vida; el que cree en mí, aunque esté muerto, vivirá.
                                        <br/><b>26</b> Y todo aquel que vive y cree en mí, no morirá eternamente. ¿Crees esto?
                                        <br/><br/>
                                        <b>Apocalipsis 20:5-6, 12-13</b>
                                        <br/><b>5</b> Pero los otros muertos no volvieron a vivir hasta que se cumplieron mil años. Esta es la primera resurrección.
                                        <br/><b>6</b> Bienaventurado y santo el que tiene parte en la primera resurrección; la segunda muerte no tiene potestad sobre estos, sino que serán sacerdotes de Dios y de Cristo, y reinarán con él mil años.
                                        <br/><b>12</b> Y vi a los muertos, grandes y pequeños, de pie ante Dios; y los libros fueron abiertos, y otro libro fue abierto, el cual es el libro de la vida; y fueron juzgados los muertos por las cosas que estaban escritas en los libros, según sus obras.
                                        <br/><b>13</b> Y el mar entregó los muertos que había en él; y la muerte y el Hades entregaron los muertos que había en ellos; y fueron juzgados cada uno según sus obras."
                              />
                            </b>
                          </p>
                          <p>
                            Nosotros creemos que las almas de los salvos son,
                            luego de la muerte, ausentes del cuerpo y presentes
                            al Señor, donde en actitud consciente ellos esperan
                            la primera resurrección, cuando el espíritu, alma y
                            cuerpo se reunirán para ser glorificados para
                            siempre con el Señor.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Lucas 23:43; Apocalipsis 20:4-6; 2 Corintios 5:8; Filipenses 1:23; Filipenses 3:21; 1 Tesalonicenses 4:16-17)"
                                }
                                content="<b>Lucas 23:43</b>
                                        <br/><b>43</b> Entonces Jesús le dijo: De cierto te digo que hoy estarás conmigo en el paraíso.
                                        <br/><br/>
                                        <b>Apocalipsis 20:4-6</b>
                                        <br/><b>4</b> Y vi tronos, y se sentaron sobre ellos los que recibieron facultad de juzgar; y vi las almas de los decapitados por causa del testimonio de Jesús y por la palabra de Dios, los que no habían adorado a la bestia ni a su imagen, y que no recibieron la marca en sus frentes ni en sus manos; y vivieron y reinaron con Cristo mil años.
                                        <br/><b>5</b> Pero los otros muertos no volvieron a vivir hasta que se cumplieron mil años. Esta es la primera resurrección.
                                        <br/><b>6</b> Bienaventurado y santo el que tiene parte en la primera resurrección; la segunda muerte no tiene potestad sobre estos, sino que serán sacerdotes de Dios y de Cristo, y reinarán con él mil años.
                                        <br/><br/>
                                        <b>2 Corintios 5:8</b>
                                        <br/><b>8</b> pero confiamos, y más quisiéramos estar ausentes del cuerpo, y presentes al Señor.
                                        <br/><br/>
                                        <b>Filipenses 1:23</b>
                                        <br/><b>23</b> Porque de ambas cosas estoy puesto en estrecho, teniendo deseo de partir y estar con Cristo, lo cual es muchísimo mejor;
                                        <br/><br/>
                                        <b>Filipenses 3:21</b>
                                        <br/><b>21</b> el cual transformará el cuerpo de la humillación nuestra, para que sea semejante al cuerpo de la gloria suya, por el poder con el cual puede también sujetar a sí mismo todas las cosas.
                                        <br/><br/>
                                        <b>1 Tesalonicenses 4:16-17</b>
                                        <br/><b>16</b> Porque el Señor mismo con voz de mando, con voz de arcángel, y con trompeta de Dios, descenderá del cielo; y los muertos en Cristo resucitarán primero.
                                        <br/><b>17</b> Luego nosotros los que vivimos, los que hayamos quedado, seremos arrebatados juntamente con ellos en las nubes para recibir al Señor en el aire, y así estaremos siempre con el Señor."
                              />
                            </b>
                          </p>
                          <p>
                            Nosotros creemos que las almas de los incrédulos
                            permanecen, después de la muerte, en la miseria
                            consciente hasta la segunda resurrección, cuando con
                            el alma y cuerpo reunidos ellos aparecerán al Gran
                            Juicio del Gran Trono Blanco, y serán lanzados en el
                            lago de fuego, donde no serán aniquilados, sino que
                            sufrirán el castigo eterno consciente.
                            <b>
                              {" "}
                              <DeclaracionTooltip
                                id={
                                  "(Lucas 16:19-26; Mateo 25:41-46; 2 Tesalonicenses 1:7-9; Judas 1:6-7; Marcos 9:43-48; Apocalipsis 20:11-15)"
                                }
                                content="<b>Lucas 16:19-26</b>
                                        <br/><b>19</b> Había un hombre rico, que se vestía de púrpura y de lino fino, y hacía cada día banquete con esplendidez.
                                        <br/><b>20</b> Había también un mendigo llamado Lázaro, que estaba echado a la puerta de aquel, lleno de llagas,
                                        <br/><b>21</b> y ansiaba saciarse de las migajas que caían de la mesa del rico; y aun los perros venían y le lamían las llagas. 
                                        <br/><b>22</b> Aconteció que murió el mendigo, y fue llevado por los ángeles al seno de Abraham; y murió también el rico, y fue sepultado.
                                        <br/><b>23</b> Y en el Hades alzó sus ojos, estando en tormentos, y vio de lejos a Abraham, y a Lázaro en su seno.
                                        <br/><b>24</b> Entonces él, dando voces, dijo: Padre Abraham, ten misericordia de mí, y envía a Lázaro para que moje la punta de su dedo en agua, y refresque mi lengua; porque estoy atormentado en esta llama.
                                        <br/><b>25</b> Pero Abraham le dijo: Hijo, acuérdate que recibiste tus bienes en tu vida, y Lázaro también males; pero ahora este es consolado aquí, y tú atormentado.
                                        <br/><b>26</b> Además de todo esto, una gran sima está puesta entre nosotros y vosotros, de manera que los que quisieren pasar de aquí a vosotros, no pueden, ni de allá pasar acá.
                                        <br/><br/>
                                        <b>Mateo 25:41-46</b>
                                        <br/><b>41</b> Entonces dirá también a los de la izquierda: Apartaos de mí, malditos, al fuego eterno preparado para el diablo y sus ángeles. 
                                        <br/><b>42</b> Porque tuve hambre, y no me disteis de comer; tuve sed, y no me disteis de beber;
                                        <br/><b>43</b> fui forastero, y no me recogisteis; estuve desnudo, y no me cubristeis; enfermo, y en la cárcel, y no me visitasteis.
                                        <br/><b>44</b> Entonces también ellos le responderán diciendo: Señor, ¿cuándo te vimos hambriento, sediento, forastero, desnudo, enfermo, o en la cárcel, y no te servimos?
                                        <br/><b>45</b> Entonces les responderá diciendo: De cierto os digo que en cuanto no lo hicisteis a uno de estos más pequeños, tampoco a mí lo hicisteis.
                                        <br/><b>46</b> E irán estos al castigo eterno, y los justos a la vida eterna.
                                        <br/><br/>
                                        <b>2 Tesalonicenses 1:7-9</b>
                                        <br/><b>7</b> y a vosotros que sois atribulados, daros reposo con nosotros, cuando se manifieste el Señor Jesús desde el cielo con los ángeles de su poder,
                                        <br/><b>8</b> en llama de fuego, para dar retribución a los que no conocieron a Dios, ni obedecen al evangelio de nuestro Señor Jesucristo;
                                        <br/><b>9</b> los cuales sufrirán pena de eterna perdición, excluidos de la presencia del Señor y de la gloria de su poder,
                                        <br/><br/>
                                        <b>Judas 1:6-7</b>
                                        <br/><b>6</b> Y a los ángeles que no guardaron su dignidad, sino que abandonaron su propia morada, los ha guardado bajo oscuridad, en prisiones eternas, para el juicio del gran día;
                                        <br/><b>7</b> como Sodoma y Gomorra y las ciudades vecinas, las cuales de la misma manera que aquellos, habiendo fornicado e ido en pos de vicios contra naturaleza, fueron puestas por ejemplo, sufriendo el castigo del fuego eterno.
                                        <br/><br/>
                                        <b>Marcos 9:43-48</b>
                                        <br/><b>43</b> Si tu mano te fuere ocasión de caer, córtala; mejor te es entrar en la vida manco, que teniendo dos manos ir al infierno, al fuego que no puede ser apagado,
                                        <br/><b>44</b> donde el gusano de ellos no muere, y el fuego nunca se apaga.
                                        <br/><b>45</b> Y si tu pie te fuere ocasión de caer, córtalo; mejor te es entrar a la vida cojo, que teniendo dos pies ser echado en el infierno, al fuego que no puede ser apagado,
                                        <br/><b>46</b> donde el gusano de ellos no muere, y el fuego nunca se apaga.
                                        <br/><b>47</b> Y si tu ojo te fuere ocasión de caer, sácalo; mejor te es entrar en el reino de Dios con un ojo, que teniendo dos ojos ser echado al infierno,
                                        <br/><b>48</b> donde el gusano de ellos no muere, y el fuego nunca se apaga.
                                        <br/><br/>
                                        <b>Apocalipsis 20:11-15</b>
                                        <br/><b>11</b> Y vi un gran trono blanco y al que estaba sentado en él, de delante del cual huyeron la tierra y el cielo, y ningún lugar se encontró para ellos.
                                        <br/><b>12</b> Y vi a los muertos, grandes y pequeños, de pie ante Dios; y los libros fueron abiertos, y otro libro fue abierto, el cual es el libro de la vida; y fueron juzgados los muertos por las cosas que estaban escritas en los libros, según sus obras.
                                        <br/><b>13</b> Y el mar entregó los muertos que había en él; y la muerte y el Hades entregaron los muertos que había en ellos; y fueron juzgados cada uno según sus obras.
                                        <br/><b>14</b> Y la muerte y el Hades fueron lanzados al lago de fuego. Esta es la muerte segunda.
                                        <br/><b>15</b> Y el que no se halló inscrito en el libro de la vida fue lanzado al lago de fuego."
                              />
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-10 mt-6">
              <p className="mx-4 mb-5 text-center">
                Así que, hermanos, estad firmes, y retened la doctrina que
                habéis aprendido, sea por palabra, o por carta nuestra.
                <b> 2 Tesalonicenses 2:15</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
