import React from "react";

export default function Galeria() {
  return (
    <section className="text-center site-section visually-hidden" id="galeria">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="row justify-content-center mt-lg-4 pt-lg-4">
            <div className="col-ms-12">
              <h2 className="mb-4 fw-bolder">Galeria de Fotos</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <p>Aqui las fotos mas importantes de la Iglesia columna 1</p>
            </div>
            <div className="col-md-6">
              <p>Aqui las fotos mas importantes de la Iglesia columna 2</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
