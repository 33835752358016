import React from 'react';
import Menu from './Menu.js';
import Home from './Home.js';

export default function Intranet() {
  return (
    <>
      <Menu></Menu>
      <Home></Home>
    </>
  )
}
