import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config.json";

export default function Eventos() {
  const [dataEventos, setDataEventos] = useState([]);

  let imagen_evento = "";
  let imagen_default = "/assets/eventos/default.jpg";

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/api/eventos/getAllPortada`)
      .then((res) => {
        setDataEventos(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const listaEventos = dataEventos.map((evento) => {
    return showEventos(evento);
  });

  function showEventos(evento) {
    const moment = require("moment");
    require("moment/locale/es");
    moment.locale("es");

    const fecha = moment(evento.fecha_evento);
    const fechaFormateada = fecha.format("DD-MM-YYYY HH:mm");

    const desc = evento.descripcion;
    const split = desc.split(/\s+/);
    const palabras = split.slice(0, 6);
    const descripcion = palabras.join(" ");

    if (evento.create_by === "admin") {
      evento.create_by = "IBE";
    }

    if (evento.imagen) {
      imagen_evento = "/assets/eventos/" + evento.imagen;
    } else {
      imagen_evento = imagen_default;
    }

    return (
      <div className="col-md-4 mt-4" key={evento.id}>
        <div className="card p-1">
          <div className="card-title news-block-title mt-2 border-bottom-2">
            <h3>{evento.titulo}</h3>
          </div>
          <div className="card-body news-block news-block-two-col d-flex">
            <div className="news-block-two-col-image-wrap">
              <img
                src={imagen_evento}
                className="news-image img-fluid"
                alt=""
              ></img>
            </div>
            <div className="news-block-two-col-info">
              <div className="news-block-body">
                <p className="text-align-left">
                  {descripcion}
                  {"... "}
                  <a
                    href={`/eventos/${evento.id}`}
                    className="news-block-title-link"
                  >
                    <br></br> {"[leer mas...]"}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-end">
              <div className="news-block-date mx-2">
                <span>
                  <i className="fa fa-calendar custom-icon me-1"></i>
                  {fechaFormateada}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="text-center site-section" id="eventos">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="row d-flex justify-content-center mt-lg-4 pt-lg-4">
            <div className="col-ms-12">
              <h2 className="mb-4 fw-bolder">Próximos eventos</h2>
            </div>
            <div className="row col-md-12">
              {listaEventos}
              <div className="col-md-4 mt-4">
                <div className="card p-1 h-100 ">
                  <div className="card-body news-block">
                    <div className=" news-block-info">
                      <div className="news-block-body">
                        <span>
                          <a href="/eventos" className="news-block-title-link">
                            <div className="d-flex justify-content-center">
                              {" "}
                              <img
                                src={imagen_default}
                                className="news-image img-fluid news-detail-image"
                                alt=""
                              ></img>
                            </div>

                            {"Ver todos los eventos "}
                            <i className="fa fa-arrow-right custom-icon me-1 fa-lg"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
