import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function DeclaracionTooltip({ id, content }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${id}`} className="tooltip-w100">
          <div
            className="text-start"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Tooltip>
      }
    >
      <span variant="secondary">{`${id}`}</span>
    </OverlayTrigger>
  );
}

export default DeclaracionTooltip;
