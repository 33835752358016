import React from "react";

export default function Historia() {
  return (
    <section className="text-justify site-section" id="historia">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="row justify-content-center mt-lg-4 pt-lg-4">
            <div className="col-ms-12 text-center">
              <h2 className="mb-4 fw-bolder">¿Quien es IBE?</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <p className="mx-4 mb-5">
                Somos un grupo de pecadores, salvos por la gracia de Dios.
                Somos personas que han puesto la fe en Jesucristo y nos
                congregamos para exaltar el Nombre de Dios, edificarnos unos a
                otros en la fe y evangelizar al mundo proclamando el evangelio
                de Cristo.
              </p>
            </div>

            <div className="accordion accordion-flush" id="accordion_hist">
              <div className="accordion-item">
                <button
                  className="accordion-outline-button btn btn-outline-info collapsed uppercase"
                  type="button"
                  id="flush-heading_hist"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse_hist"
                  aria-expanded="false"
                  aria-controls="flush-collapse_hist"
                >
                  Continuar leyendo sobre nuestra historia...
                </button>
                <div
                  id="flush-collapse_hist"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading_hist"
                  data-bs-parent="#accordion_hist"
                >
                  <div className="accordion-body small text-justify p-0">
                    <div className="row col-md-12 mt-4">
                      <div className="col-md-6">
                        <p className="mx-4">
                          La Iglesia Bautista Esperanza, nombrada así por su
                          pastor fundador, el misionero Jason Kenney, ha estado
                          en funcionamiento desde el año 2010. Su fundación,
                          inicialmente planificada para febrero de ese año, fue
                          retrasada por el terremoto que sacudió a la zona
                          centro sur de nuestro país. Iniciando sus cultos en
                          abril de es mismo año, en el sector del barrio Las
                          Rosas, Maipú, ha sido una iglesia caracterizada por su
                          compromiso con la gran comisión y la predicación
                          bíblica y expositiva de la Palabra de Dios. La
                          iglesia, al tener un crecimiento tanto númerico, como
                          en la madurez de la congregación, pudo el año 2022
                          comprar una propiedad en el barrio para que, habiendo
                          pasado por distintos locales, pueda asentarse en un
                          lugar donde predicar el evangelio hasta que el Señor
                          lo permita.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mx-4">
                          Desde el año 2019, el misionero Jason Kenney,
                          establecía un nuevo liderazgo en la Iglesia, el pastor
                          Fabián Maturana, el cual por unanimidad de los
                          miembros fue aceptado como el nuevo pastor. La iglesia
                          tiene un gobierno congregacional y todos los miembros
                          son parte de las decisiones. Eso ha llevado a la
                          iglesia a mantener un enfoque en misiones y la
                          evangelización, donde se apoya economicamente a 12
                          misioneros y plantadores de iglesia en distintos
                          lugares. Cada miembro de nuestra iglesia ha sido vital
                          para mantener la pureza del evangelio de nuestro Señor
                          Jesucristo, y traer la luz de nuestro Dios a todo este
                          barrio. Rogamos que su Santo Espíritu, dirija a
                          nuestra congregación siempre como lo hizo en los días
                          de la Iglesia primitiva registrado en el libro de los
                          Hechos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-ms-12 visually-hidden">
              <div className="my-4 text-center ">
                <h2 className="mb-4">¿Quieres conocer a Jesús?</h2>
              </div>
              <div className="col-md-12 d-flex justify-content-center">
                <iframe
                  width="866"
                  height="487"
                  src="https://www.youtube.com/embed/8t-HqhU_YIw"
                  title="Comprado con sangre por Cristo - IBE (Himno)"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="col-md-12 d-flex justify-content-center mt-4">
                <a className="btn btn-sm btn-primary" href="#contacto">
                  Quiero Conocer más!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
