import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../config.json";

export default function EventosDetalle() {
  const [dataEventos, setDataEventos] = useState([]);
  const params = useParams();
  let imagen_default = "";
  
  useEffect(() => {
    const btn_allevent = document.getElementById("btn_allevent");

    let api = `${config.apiBaseUrl}/api/eventos/getall`;

    if (params.id) {
      api = `${config.apiBaseUrl}/api/eventos/getSingle/${params.id}`;
    } else {
      if (btn_allevent) {
        btn_allevent.remove();
      }
    }

    axios
      .get(api)
      .then((res) => {
        setDataEventos(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const mainNav = document.getElementById("mainNav");
    const navbarShrink = () => {
      if (mainNav) {
        mainNav.style.backgroundColor = "var(--bs-primary-dark)";
        mainNav.style.borderBottom = "3px solid var(--bs-secondary)";
      }
    };

    navbarShrink();
  }, [params.id]);

  const listaEventos = dataEventos.map((evento) => {
    return showEventos(evento);
  });

  function showEventos(evento) {
    const moment = require("moment");
    require("moment/locale/es");
    moment.locale("es");

    const fecha = moment(evento.fecha_evento);
    const fechaFormateada = fecha.format(" dddd D [de] MMMM [del] YYYY ");
    const hora = fecha.format(" HH:mm [horas]");

    if (evento.create_by === "admin") {
      evento.create_by = "IBE";
    }

    if (evento.imagen) {
      imagen_default = "/assets/eventos/" + evento.imagen;
    } else {
      imagen_default = "/assets/eventos/default.jpg";
    }

    return (
      <div className="row col-md-12 mt-5" key={evento.id}>
        <div className="card p-1 news-block-two-col d-flex">
          <div className="row col-md-12 card-body">
            <div className="col-md-4">
              <img
                src={imagen_default}
                className="news-image img-fluid border-radius-medium"
                alt=""
              ></img>
            </div>
            <div className="col-md-8  text-align-left">
              <div className="news-block-title mb-2">
                <h2>{evento.titulo}</h2>
              </div>
              <div className="news-block-body">
                <p>{evento.descripcion}</p>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-end">
              <div className="news-block-date mx-2">
                <span className="camel-case">
                  <i className="fa fa-calendar custom-icon me-1"></i>
                  {fechaFormateada}{" "}
                  <i className="fa fa-clock custom-icon me-1"></i>
                  {hora}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="text-center site-section mt-8">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="row justify-content-center mt-lg-4 pt-lg-4">
            <div className="col-md-12">
              <h2 className="mb-4 fw-bolder">Próximos eventos</h2>
            </div>
            <div className="col-md-12">{listaEventos}</div>
            <div className="d-flex justify-content-center">
              <a className="btn btn-secondary m-4 p-2" href="/">
                <i className="fa fa-home"></i>
                <span> Inicio</span>
              </a>
              <a
                id="btn_allevent"
                className="btn btn-primary m-4 p-2"
                href="/eventos"
              >
                <i className="fa fa-calendar"></i>
                <span> Todos los eventos</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
