import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/shared/Footer.js";
import Layout from "./components/shared/Layout.js";
import Intranet from "./components/intranet/Intranet.js";
import EventosDetalle from "./components/home/EventosDetalle.js";
import Eventos from "./components/intranet/Eventos.js";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}></Route>
            <Route path="/eventos" element={<EventosDetalle />}></Route>
            <Route path="/eventos/:id" element={<EventosDetalle />}></Route>

            <Route path="/intranet" element={<Intranet />}></Route>
            <Route path="/intranet/eventos" element={<Eventos />}></Route>
            <Route path="/intranet/galeria" element={<Intranet />}></Route>
          </Routes>
        </BrowserRouter>
      </header>
      <footer className="footer text-white-75">
        <Footer></Footer>
      </footer>
    </div>
  );
}

export default App;
