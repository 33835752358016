import Historia from "../home/Historia.js";
import Contacto from "../home/Contacto.js";
import Eventos from "../home/Eventos.js";
import Galeria from "../home/Galeria.js";
import Home from "../home/Home.js";
import Menu from "../home/Menu.js";
import Declaracion from "../home/Declaracion.js";

export default function Layout() {
  return (
    <>
      <Menu></Menu>
      <Home></Home>
      <Historia></Historia>
      <Declaracion></Declaracion>
      <Eventos></Eventos>
      <Galeria></Galeria>
      <Contacto></Contacto>
    </>
  );
}
