import React from 'react'

export default function Home() {
  return (
    <header className="masthead site-section">
      <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <h1 className="mx-auto my-0 text-uppercase">
              IGLESIA BAUTISTA ESPERANZA
            </h1>
            <h2 className="text-white-50 mx-auto mt-2 mb-5 fw-bold">
              Somos una Iglesia para toda la familia.
            </h2>
            <a className="btn btn-primary" href="#contacto">
              ¡Ven a visitarnos!
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
