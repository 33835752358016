import React from "react";

export default function Contacto() {
  return (
    <section className="site-section contacto" id="contacto">
      <div className="container px-4 px-lg-5">
        <div className="row col-md-12 gx-4 gx-lg-5">
          <div className="row gx-4 gx-lg-5">
            <div className="row justify-content-center">
              <div className="col-ms-12">
                <h2 className="mb-4 fw-bolder">Información de contacto</h2>
              </div>
            </div>
            <div className="row col-md-12 justify-content-center">
              <div className="col-md-12">
                <p>
                  Dios te bendiga en esta hora, si quieres aprender mas de la
                  palabra de Dios o necesitas ayuda en tu vida espiritual, nos
                  puedes contactar o acercarte a nuestros horarios de culto
                </p>
                <p>
                  A continuación te presentamos los distintos canales por cual
                  nos puedes contactar, via Teléfono, WhatsApp, Correo
                  Electronico o en Direccion de nuestro templo de adoración.
                </p>
              </div>
            </div>
          </div>
          <div className="row col-md-12 mb-3 mb-md-0">
            <div className="col-md-4 mt-3">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-clock text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Horarios</h4>
                  <hr className="my-4 mx-auto" />
                  <div className="small text-black-50 mt-5">
                    <h3 className="fw-bold">Culto de oración</h3>
                    <span>Miercoles 20:00 horas</span>
                    <div className="mt-xxl-5 mb-xxl-5"></div>
                    <h3 className="fw-bold">Culto de adoración</h3>
                    <span>Domingo 11:00 horas</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-3">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-map text-primary mb-2"></i>
                  <h5 className="text-uppercase m-0">
                    Tinguiririca 3691, Maipú, Región Metropolitana.
                  </h5>
                  <hr className="my-4 mx-auto" />
                  <div className="small text-black-50">
                    <iframe
                      title="IBE"
                      className=""
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d989.2099623579364!2d-70.74123237060765!3d-33.49376769477676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c347b2a65535%3A0xaa498f1e33c98a37!2sIglesia%20Bautista%20Esperanza!5e0!3m2!1ses-419!2scl!4v1708389084281!5m2!1ses-419!2scl"
                      style={{ border: 0, width: "90%", height: 400 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row col-md-12 mb-3 mb-md-0">
            <div className="col-md-4 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="tel:+56987385904"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-mobile-alt text-primary mb-2"></i>
                    <h5 className="text-uppercase m-0">Teléfono</h5>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>(+56) 9 8738 5904</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="mailto:pastor@iglesiabautistaesperanza.cl"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-envelope text-primary mb-2"></i>
                    <h5 className="text-uppercase m-0">Email</h5>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>Pastor Fabian Maturana Leiva</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="https://wa.me/56987385904"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fab fa-whatsapp text-primary mb-2"></i>
                    <h5 className="text-uppercase m-0">WhatsApp</h5>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>(+56) 9 8738 5904</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="https://www.instagram.com/ibesperanzachile/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fab fa-instagram text-primary mb-2"></i>
                    <h5 className="text-uppercase m-0">Instagram</h5>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>@ibesperanzachile</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="https://www.facebook.com/esperanzaenmaipu"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fab fa-facebook text-primary mb-2"></i>
                    <h5 className="text-uppercase m-0">Facebook</h5>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>@esperanzaenmaipu</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 mt-3">
              <a
                className="fw-bold text-decoration-none"
                href="https://www.youtube.com/@iglesiabautistaesperanza-c7841"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fab fa-youtube text-primary mb-2"></i>
                    <h5 className="text-uppercase m-0">YouTube</h5>
                    <hr className="my-4 mx-auto" />
                    <div className="small text-black-50">
                      <span>@iglesiabautistaesperanza</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
