import React, { useEffect } from "react";
import logo from "../../assets/img/logo.png";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";

function Menu() {
  useEffect(() => {
    const mainNav = document.getElementById("mainNav");
    const navbarShrink = () => {
      if (!mainNav) {
        return;
      }
      if (window.scrollY === 0) {
        mainNav.classList.remove("navbar-shrink");
      } else {
        mainNav.classList.add("navbar-shrink");
      }
    };

    const handleNavbarItemClick = () => {
      const btnToggler = document.querySelector(".navbar-toggler");
      const navbar = document.getElementById("navbarResponsive");
      if (navbar.classList.contains("show")) {
        btnToggler.click();
      }
    };


    if (mainNav) {
      new bootstrap.ScrollSpy(document.body, {
        target: "#mainNav",
        rootMargin: "0px 0px -40%",
      });
    }

    navbarShrink();

    document.addEventListener("scroll", navbarShrink);

    const responsiveNavItems = document.querySelectorAll(
      "#navbarResponsive .nav-link"
    );

    responsiveNavItems.forEach((responsiveNavItem) => {
      responsiveNavItem.addEventListener("click", handleNavbarItemClick);
    });

    const hash = window.location.hash;

    if (hash) {
      const href = hash.slice(1);
      const targetElement = document.querySelector(`[href="#${href}"]`);
      if (targetElement) {
        targetElement.click();
      }
    }

    return () => {
      document.removeEventListener("scroll", navbarShrink);
      responsiveNavItems.forEach((responsiveNavItem) => {
        if (hash) {
          responsiveNavItem.classList.toggle("active");
          responsiveNavItem.removeEventListener("click", handleNavbarItemClick);
        }
      });
    };
  });

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top"
      id="mainNav"
    >
      <div className="container px-4 px-lg-5">
        <a className="navbar-brand" href="#page-top">
          <img className="logo" src={logo} alt="Inicio"></img>
        </a>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/#historia">
                <span className="nav-text text-white-75">Nuestra Historia</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#declaracion">
                <span className="nav-text text-white-75">
                  Declaración de Fe
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#eventos">
                <span className="nav-text text-white-75">Eventos</span>
              </a>
            </li>
            <li className="nav-item visually-hidden">
              <a className="nav-link" href="/#galeria">
                <span className="nav-text text-white-75">Galeria</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#contacto">
                <span className="nav-text text-white-75">Contacto</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
